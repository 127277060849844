//import React from "react";
import React, { Suspense, useState, useRef, createContext, useContext, useEffect, useMemo, useCallback } from "react";
import { Canvas, extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Cloud, Sky, Sphere } from "@react-three/drei";
import { CameraControls } from "./Camera";
//import { Html, useProgress, Loader, Grid } from '@react-three/drei'
import { useParams, useLocation } from "react-router-dom";
import ArrowKeysReact from 'arrow-keys-react';
import StoryEditOverlay from './components/StoryEditOverlay'
import AppStoryExperience from "./AppStoryExperience";
import { useNavigate } from 'react-router-dom';
//import anime, { set } from "animejs";
import { GetContentPosition } from "./components/UtilFunctions";
import * as THREE from 'three'
import { Water } from 'three-stdlib'
import ReactPlayer from "react-player";

//import QRCode from "react-qr-code";
import { CompHSChat } from "./components/CompHSChat";
//import CompModal from "./components/CompModal";
//import CompContact from './CompContact'

// import ExpTOC from "./experience/ExpTOC";
// import { Doughnut } from 'react-chartjs-2';

import { v4 as uuidv4 } from 'uuid'

//import { useVideoPlayer } from "" // from "../events/bridge";
import { useVidListener, emitVid } from "./events/bridge";
//import { useOnChange, emitOnChange } from "./events/bridge";
import { useOnChangeListener, emitOnChange } from "./events/bridge";

import ExpHTMLEcoCard from "./experience/ExpHTMLEcoCard";

import { Offline, Online } from "react-detect-offline";

// /** @jsx jsx */  import { jsx } from '@emotion/core'
// import css from "@emotion/css";
//import styled from 'styled-components';

//import { css } from '@emotion/css'
//import { Perf } from 'r3f-perf'
import useScreenOrientation from 'react-hook-screen-orientation'

// Grab data for story
import { db } from '/firebase'
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  where,
  addDoc,
  getDocs,
  deleteDoc,
  serverTimestamp,
  increment,
} from 'firebase/firestore'

import NavigationTimelineSolo from "./components/NavigationTimelineSolo";
import NavigationTimelineMainSub from "./components/NavigationTimelineMainSub";
import NavigationProcessChevron2 from "./components/NavigationProcessChevron2";
//import { G } from "@react-pdf/renderer";

import BarLoader from "react-spinners/BarLoader";

import NavigationProcessChevron from "./components/NavigationProcessChevron";


// NEW PRIMATIVES...
import ExpMeshDecal from "./experience/ExpMeshDecal";
import ExpMeshArrow from "./experience/ExpMeshArrow";


import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';

// import { Group } from "three";
// import { on } from "events";

//import CircularProgress from 'react-native-circular-progress-indicator';
//import SessionContextProvider, {SessionContext} from "./context/SessionDataContext";
//import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";

export const SessionContext = createContext([])
export const SceneContext = createContext([])
export const StoryContext = createContext([])


const AppStoryPlayer = () => {

  //console.log('AppStoryPlayer...!!!')

  const { storyId } = useParams()
  const { shareCode } = useParams()

  const horizontalSpace = 40
  const cameraRef = useRef()
  const vidRef = useRef()
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [muted, setMuted] = useState(false);


  const handleProgressClick = (event) => {
    const progressWrapper = document.getElementById('videoProgressWrapper');
    const clickPosition = event.clientX - progressWrapper.getBoundingClientRect().left;
    const clickRatio = clickPosition / progressWrapper.offsetWidth;
    const duration = vidRef.current.getDuration();
    const newTime = duration * clickRatio;
    vidRef.current.seekTo(newTime);
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  // Starting default positions
  //const [position, setPosition] = useState({ x: 0, y: 200, z: 600 })

  const [position, setPosition] = useState({ x: 0, y: 50, z: 600 })
  const [target, setTarget] = useState({ x: 0, y: 0, z: -10 })
  //const [notificationCount, setNotificationCount] = useState(0)

  // Keep for PC Story that uses a reposition on start of experience
  useEffect(() => {
    // Check for PC Story 
    if (storyId == 'nG5Zvkp1bTNP00eLZRI0') {
      //console.log('PC Story')
      setTimeout(() => {
        onChange(0)
      }, 1000)
    }
  }, [])

  // Try Useeffect on the following content
  const [sessionData, setSessionData] =
    useState(
      {
        activeIndex: 0,
        videoMode: 1,
        deliveryMode: 2,
        themeSkyConfig: '',
        modeVideo: 'fullscreen',
        themeGroundConfig: '/components/live-base-a.glb',
        playModeActive: 0,
        screenshotModeActive: false,
        quickScreenshotModeActive: false,
      }
    )


  // useEffect(() => {
  //   setSessionData({activeIndex: 0,
  //     videoMode: 1,
  //     deliveryMode: 2,
  //     themeSkyConfig: '',
  //     modeVideo: 'fullscreen',
  //     themeGroundConfig: '/components/live-base-a.glb',
  //     playModeActive: 0,
  //     screenshotModeActive: false,
  //     quickScreenshotModeActive: false,}
  //   ), []})    

  const [sceneData, setSceneData] = useState([])
  const [storyData, setStoryData] = useState([])
  const navigate = useNavigate()

  // Marker position
  //const [sectionActiveMarkerPosition, setSectionActiveMarkerPosition] = useState([0, -1, 0]);

  // Handle data load and timing with components 
  const [dataLoading, setDataLoading] = useState(true)
  const [storyLoaded, setStoryLoaded] = useState(false)
  const [sceneLoaded, setSceneLoaded] = useState(false)

  //const [playMode]


  window.addEventListener('error', console.log)
  //console.error('error') // has the same kind of attribute as the firebase message


  //const [vidProgress, setVidProgress] = useState(0)
  var vidProgress = 0
  var vidTotal = 0
  var videoElapsedTime = 0
  var progressMeter = 0;

  //useEffect(() => {
  const [showMediaPlayer, setShowMediaPlayer] = useState(true)

  //const ListenerComponent = () => {
  useVidListener((action) => {
    //    console.log(action.progress)

    if (action.length != undefined) {
      vidTotal = action.length
      //console.log('SET TOTAL LENGTH')
      //console.log(vidTotal)
      var videoDuration = new Date(vidTotal * 1000).toISOString().substring(14, 19)
      if (document.getElementById('vidDuration')) {
        document.getElementById('vidDuration').innerHTML = videoDuration;
        // document.getElementsByClassName('only-media').classList.add('animate__animated  animated__fadeIn animated__delay-1s')
        // document.getElementsByClassName('only-media').classList.remove('noshow')

        var els = document.getElementsByClassName('only-media')

        Array.from(els).forEach((el) => {
          // Do stuff here
          //console.log(el.tagName);

          //el.classList.add('noshow')
          el.classList.remove('noshow')
          el.classList.add('animate__animated')
        });
      }
      // noshow
      //setShowMediaPlayer(false)
    }
    else {
      // document.getElementsByClassName('only-media').classList.remove('animate__animated  animated__fadeIn animated__delay-1s')
      // document.getElementsByClassName('only-media').classList.add('noshow')
      var els = document.getElementsByClassName('only-media')

      Array.from(els).forEach((el) => {
        // Do stuff here
        //console.log(el.tagName);
        el.classList.remove('animate__animated')
        el.classList.remove('noshow')
      });
    }

    if (vidTotal > 0) {
      if (action.progress) {
        if (document.getElementById('vidElapsed')) {
          videoElapsedTime = new Date(action.progress * 1000).toISOString().substring(14, 19)
          document.getElementById('vidElapsed').innerHTML = videoElapsedTime || '';
          progressMeter = action.progress / vidTotal * 100;
        }
      }
    }

    const vidProgressElement = document.getElementById('vidProgress');
    if (vidProgressElement) {
      vidProgressElement.style.width = `${progressMeter}%`;
    }

  })


  useOnChangeListener((id) => {
    //console.log('GOT ID...!!!')
    // console.log('GOT ID...!!!')
    // console.log(id.id)
    //onChange(1)

    //setVideoPlaying(false)
    onChange(id.id + 0)

  })

  const [orbitActive, setOrbitActive] = useState(1)

  // Get data
  useEffect(() => {

    try {
      const unsub = onSnapshot(doc(db, 'stories', storyId), (doc) => {
        setStoryData(doc.data())
        // console.log('GOT DATA...!')
        // console.log(doc.data());
        setStoryLoaded(true)

        if (doc != undefined) {
          document.title = doc.data().name
          //unsub()
        }
      },
        (error) => {
          navigate('/404')
        })

      const q = query(collection(db, 'stories', storyId, 'scenes'), orderBy("order"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let sceneArr = []
        querySnapshot.forEach((doc) => {
          sceneArr.push({ ...doc.data(), id: doc.id })
        })

        // console.log('SCENE DATA')
        // console.log(sceneArr)

        setSceneData(sceneArr)
        setSceneLoaded(true)





        // if (sceneArr.length > 0) {

        //   // setPosition({ x: sceneArr[0].pos.x + 0, y: sceneArr[0].pos.y + 50, z: sceneArr[0].pos.z + 600 })
        //   // setTarget({ x: 0, y: 0, z: -10 })
        //   //onChange(0)
        // }

      },
        (error) => {
        })
      return () => {
        unsubscribe()
      }

    } catch (error) {
      // console.error('###################### Error loading data... ######################');
      // console.error(error);
      // console.error('////////////////////// Error loading data... //////////////////////');
    }

  }, [])


  useEffect(() => {
    if (storyLoaded & sceneLoaded) {

      setOrbitActive(storyData.cameraOrbitOn)
      // console.log('ORBIT ACTIVE DB    : ' + storyData.cameraOrbitOn)
      // console.log('ORBIT ACTIVE State : ' + orbitActive)

      // Check for sharing mode
      if (location.pathname.includes('/share/')) {

        //const shareCode = location.pathname.split('/share/')[1]
        //const { shareCode } = useParams()

        if (shareCode) {
          //console.log('SHARE CODE: ' + shareCode)
          // storyId, userEmail, storyName, shareCode

          const storyName = storyData.name
          const userEmail = 'john@brevit3.com'
          const storyId = storyData.id
          const storyDescription = storyData.description
          const storyImage = storyData.previewThumb

          //storyId, userEmail, storyName, shareCode, storyDescription, storyImage

          try {

            // shareCode

            const response = fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/shareOpenNotification', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ storyId, userEmail, storyName, shareCode, storyDescription, storyImage }),
            });




            if (response.ok) {
            } else {
            }
          } catch (error) {
          }

        }
      }

      // Add one second to load...
      setTimeout(() => {

        if (storyId == 'nG5Zvkp1bTNP00eLZRI0' || storyId == 'tX58RhI2q2wDKPBMkOvO' || storyId == 'GOTPFPnPZ3H42ZTuXDOM') {
          //console.log('PC Story')
          setTimeout(() => {
            //console.log("Moved to new spot...!")
            onChange(0)
            setDataLoading(false)
          }, 1000)
        }
        else {
          setDataLoading(false)
        }
      }, 500)
    }
  }, [storyLoaded, sceneLoaded])





  // Analytics code
  const [visitorId, setVisitorId] = useState('');
  const [fingerprint, setFingerprint] = useState('');

  useEffect(() => {
    // Check for existing visitor ID in localStorage
    let id = localStorage.getItem('visitorId');
    if (!id) {
      // Generate a new UUID if no existing ID
      id = uuidv4();
      localStorage.setItem('visitorId', id);
    }
    setVisitorId(id);

    // Create a simple fingerprint
    const fp = `${navigator.userAgent}|${navigator.language}|${screen.width}x${screen.height}|${new Date().getTimezoneOffset()}`;
    setFingerprint(fp);

    // You would typically send this data to your analytics service here
    // console.log('Visitor ID:', id);
    // console.log('Fingerprint:', fp);
  }, []);







  const [analyticsPackPrior, setAnalyticsPackPrior] = useState({});
  const [analyticsDocPrior, setAnalyticsDocPrior] = useState({});

  const sceneStartTime = useRef(null);

  useEffect(() => {
    sceneStartTime.current = Date.now();
    return () => {
      sceneStartTime.current = null;
    };
  }, []);




  const PersonGrid = ({ sceneData }) => {
    // Function to extract person data from viznodes and ensure unique names
    const extractUniquePersonData = (scenes) => {
      const allPersons = scenes.flatMap(scene =>
        scene.viznodes
          .filter(node => node.type === "Person" && node.media && node.media.length > 0)
          .map(node => node.media[0])
      );

      // Use a Map to keep only unique entries based on personaName
      const uniquePersons = new Map();
      allPersons.forEach(person => {
        if (!uniquePersons.has(person.personaName)) {
          uniquePersons.set(person.personaName, person);
        }
      });

      return Array.from(uniquePersons.values());
    };

    const personData = extractUniquePersonData(sceneData);

    return (
      <div className="container mx-auto">
        <div className="">
          {personData.map((displayPersona, index) => (
            // <div key={person.personaName + '-' + index} className="text-gray-300 px-2 pt-3 flex pb-2 rounded-md bg-zinc-100">
            //   <img
            //     src={person.image}
            //     alt={person.personaName}
            //     className="flex-none h-32"
            //   />
            //   <div className="p-1 text-left flex-1">
            //     <div className="text-zinc-900 text-base">{person.personaName}</div>
            //     <div className="text-zinc-900 text-base">{person.personaRole}</div>
            //     <div className="text-zinc-900 text-base">{person.personaDetail.replaceAll('*', '•')}</div>
            //   </div>
            // </div>
            <div className="persona-card-bodyxx mb-4 cursor-pointer rounded-md overflow-hiddenxxx"
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                borderColor: storyData.uiStyle['super-highlight-color'],
                //width: '500px',
                padding: '0rem',
                minHeight: '0px !important',
                borderRadius: '.5rem',
              }}
              key={displayPersona.personaName + '-' + index}
            >
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 flex flex-col justify-end h-[160px] ">
                  {/* <div className="bg-no-repeat bg-bottom h-32 mx-auto"
                    style={{
                      backgroundImage: `url(` + displayPersona.image + `)`,
                      backgroundSize: `contain`,
                      backgroundPosition: 'bottom',
                    }}
                  ></div> */}

                  <img
                    src={displayPersona.image}
                    alt={displayPersona.personaName}
                    className="h-auto"
                  />

                </div>
                <div className="col-span-6">
                  <div className="name pt-4 text-lg font-semibold"
                    style={{
                      color: storyData.uiStyle['super-main-title-color']
                    }}
                  >
                    {displayPersona.personaName}
                  </div>
                  {/* <div className="role"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {displayPersona.personaRole}
                  </div> */}
                  <div className="role description mt-4"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {/* {displayPersona.personaDetail.replaceAll('*', '\n•')} */}
                    <ul>
                      {displayPersona.personaDetail.split('*').map((item, index) => (
                        item.trim() && <li key={index} className="list-disc ml-4">{item.trim()}</li>
                      ))}
                    </ul>


                  </div>
                </div>
                <div className="col-span-2">
                  <div className="h-12 w-12 mt-12 p-1 bg-white/10 rounded-full flex justify-center items-center">
                    <img src="/util/app/b3-admin-play.svg" className="w-full p-2" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };


  function onChange(idx = 0) {


    if (storyLoaded && sceneLoaded) {

      //const shareCode = location.pathname.split('/share/')[1]
      //const { shareCode } = useParams()

      // console.log('PRIOR PACK...!!!!')
      // console.log(analyticsPackPrior)

      // Only run during sharing mode
      if (location.pathname.includes('/share/')) {
        const currentTime = Date.now();
        const engagementTime = sceneStartTime.current
          ? Math.floor((currentTime - sceneStartTime.current) / 1000)
          : 0;

        const analyticsPack = {
          storyId: storyData.id,
          shareCode: shareCode,
          storyName: storyData.name,
          sceneName: sceneData[idx].name,
          sceneId: sceneData[idx].id,
          sceneIndex: idx,
          scenePriorId: analyticsPackPrior.sceneId || null,
          scenePriorIndex: analyticsPackPrior.sceneIndex || null,
          engagementTime: 0,
          engagementTags: sceneData[idx].tags || [],
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          userAnon: fingerprint,
          userAccount: '',
          userAgent: navigator.userAgent,
          createdAt: serverTimestamp(),
        };

        if (
          //(analyticsPackPrior.sceneId != sceneData[idx].id) || 
          //(analyticsPackPrior.sceneIndex != idx)) 
          (analyticsPackPrior.sceneIndex != idx)
          //(analyticsPackPrior == null && idx == 0)
        ) {

          // console.log('SCENE CHANGE: ' + idx);
          // console.log(analyticsPack);

          const addAnalytics = async () => {
            try {
              const analyticsRef = await addDoc(collection(db, 'analytics'), analyticsPack);
              //console.log('Analytics added with ID: ', analyticsRef.id);

              // Time details exist
              if (analyticsPackPrior.sceneId != null) {

                if (analyticsDocPrior) {
                  const analyticsPriorDoc = doc(db, 'analytics', analyticsDocPrior.id);
                  await updateDoc(analyticsPriorDoc, {
                    engagementTime: engagementTime //analyticsPack.engagementTime
                  });
                }

              }

              setAnalyticsDocPrior(analyticsRef);

            } catch (error) {
              console.error('Error adding analytics: ', error);
            }
          };
          addAnalytics();
        }

        setAnalyticsPackPrior(analyticsPack);

        // Reset the start time for the new scene
        sceneStartTime.current = currentTime;
      }

      var els = document.getElementsByClassName('only-media');
      Array.from(els).forEach((el) => {
        el.classList.add('noshow');
      });

      // Clean up buttons - select new active based on index
      var buttonIdx = 0;

      // Starting values here
      let position = { x: 10, y: 10, z: 10 };
      let target = { x: 0, y: 0, z: 0 };

      setSessionData(prevState => ({
        ...prevState,
        activeIndex: idx
      }));

      let xPos = 0;
      let yPos = 60;
      let zPos = 240;
      let xTar = xPos;
      let yTar = 15; // Vert camera position - higher / lower...
      let zTar = 20;

      if (storyData) {
        if (idx < 0) {
          idx = 0;
        }

        if (idx >= sceneData.length) {
          idx = sceneData.length - 1;
        }

        if (sceneData[idx]) {
          if (sceneData[idx].layout == 'manual' || sceneData[idx].layout == true) {
            // Camera
            xPos = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0] + sceneData[idx].camOffset.position.x;
            yPos = GetContentPosition(storyData, sceneData, idx, 0, yPos, 0)[1] + sceneData[idx].camOffset.position.y;
            zPos = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2] + sceneData[idx].camOffset.position.z;

            // Target
            xTar = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0] + sceneData[idx].camOffset.target.x;
            yTar = GetContentPosition(storyData, sceneData, idx, 0, yTar, 0)[1] + sceneData[idx].camOffset.target.y;
            zTar = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2] + sceneData[idx].camOffset.target.z;
          } else {
            // Horizontal
            xPos = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0];
            xTar = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0];

            // Vertical
            yPos = GetContentPosition(storyData, sceneData, idx, 0, yPos, 0)[1];
            yTar = GetContentPosition(storyData, sceneData, idx, 0, yTar, 0)[1];

            // Front / Back
            zPos += GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2];
            zTar += GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2];
          }
        }
      }

      // TODO - update to account for sidebar with video embed
      if (sessionData.modeVideo == 'pip') {
        xPos -= 8;
        xTar -= 8;
      }

      if (storyData.navigation == 'playlist-sidebar2') {
        xPos -= 6;
        xTar -= 6;
        zPos += 15;
      }

      position = { x: xPos, y: yPos, z: zPos };
      target = { x: xTar, y: yTar, z: zTar };

      setPosition(position);
      setTarget(target);

      // Always run with video playing when moving to a new scene, just like YouTube
      if (storyData.navigation == 'playlist-sidebar2') {
        setVideoPlaying(true);

        if (document.querySelector("#scrollPlaylist")) {
          const scroller = document.querySelector("#scrollPlaylist");
          scroller.scrollTop = 44 * idx;
        }
      }
    }
  }





  const UploadStoryMap = async (file) => {
    const data = new FormData()
    data.append("file", file)
    data.append(
      "upload_preset",
      'qblr702c'
    )
    data.append("cloud_name", 'djdw2s79u')
    data.append("folder", "Cloudinary-React")

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/djdw2s79u/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      const storyRef = doc(db, 'stories', storyId)

      updateDoc(storyRef, {
        ...storyData,
        previewThumb: res.secure_url
      })
    } catch (error) {
      alert(error)
      //console.log(error)
    }
  }





  const cameraInfoRef = useRef();
  const cameraControlInfoRef = useRef();

  const handleCameraReady = useCallback((camera, control) => {
    cameraInfoRef.current = camera;
    cameraControlInfoRef.current = control;
  }, []);

  const getCameraInfo = () => {
    if (cameraInfoRef.current) {

      if (cameraControlInfoRef.current) {
        const zDelta = 70
        const vertDelta = 60

        const offsetX = (sceneData[sessionData.activeIndex].pos.x - cameraInfoRef.current.position.x) * -1
        const offsetY = cameraInfoRef.current.position.y - vertDelta
        const offsetZ = (sceneData[sessionData.activeIndex].pos.z + cameraInfoRef.current.position.z) // + zDelta

        console.log('Offset Position X', offsetX)
        console.log('Offset Position Y: ', offsetY)
        console.log('Offset Position Z', offsetZ)

        // Position Update
        sceneData[sessionData.activeIndex].camOffset.position.x = offsetX //sceneCamoffsetPositionX
        sceneData[sessionData.activeIndex].camOffset.position.y = offsetY //sceneCamoffsetPositionY
        sceneData[sessionData.activeIndex].camOffset.position.z = offsetZ

        // Target Update
        // x30, y15
        // x70, y15
        // x110, y15
        // z 300 -> 230....

        const targetX = (sceneData[sessionData.activeIndex].pos.x - cameraControlInfoRef.current.current.target.x) * -1
        const targetY = cameraControlInfoRef.current.current.target.y - 15
        const targetZ = cameraControlInfoRef.current.current.target.z // + 70

        sceneData[sessionData.activeIndex].camOffset.target.x = targetX
        sceneData[sessionData.activeIndex].camOffset.target.y = targetY
        sceneData[sessionData.activeIndex].camOffset.target.z = targetZ

        console.log('Offset Target X', targetX)
        console.log('Offset Target Y: ', targetY)
        console.log('Offset Target Z', targetZ)

        sceneData[sessionData.activeIndex].layout = true

        const sceneRef = doc(db, 'stories', storyId, 'scenes', sceneData[sessionData.activeIndex].id);
        updateDoc(sceneRef, sceneData[sessionData.activeIndex])
      }
    }

  };






  const exportRef = useRef();

  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {
  //     const { gl, scene, camera } = useThree()
  //     gl.render(scene, camera)
  //     const file = gl.domElement.toDataURL('image/png');

  //     // UNCOMMENT FOR PRODUCTION
  //     UploadStoryMap(file)

  //     setSessionData(prevState => ({
  //       ...prevState,
  //       screenshotModeActive: false
  //     }))
  //   }
  // }

  const Screenshot = () => {
    if (sessionData.screenshotModeActive) {
      const { gl, scene, camera } = useThree()
      gl.render(scene, camera)
      const file = gl.domElement.toDataURL('image/png');

      // UNCOMMENT FOR PRODUCTION
      UploadStoryMap(file)

      setSessionData(prevState => ({
        ...prevState,
        screenshotModeActive: false
      }))
    }
  }


  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {
  //     const { gl, scene, camera } = useThree()
  //     gl.render(scene, camera)
  //     const file = gl.domElement.toDataURL('image/png');

  //     // Create link element and trigger download
  //     const link = document.createElement('a')
  //     link.download = `screenshot-${Date.now()}.png`
  //     link.href = file
  //     link.click()
  //     link.remove() // Clean up the DOM

  //     setSessionData(prevState => ({
  //       ...prevState,
  //       screenshotModeActive: false
  //     }))
  //   }
  // }

  // WORKS FOR DREI WITH IMAGES!!!!
  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {
  //     const { gl, scene, camera } = useThree()

  //     // First render the WebGL canvas
  //     gl.render(scene, camera)

  //     // Use html2canvas to capture the entire canvas container including HTML overlays
  //     html2canvas(document.getElementById('main-stage'), {
  //       useCORS: true, // Enable CORS for external images
  //       allowTaint: true,
  //       backgroundColor: null,
  //       logging: false,
  //       scale: window.devicePixelRatio, // Maintain resolution
  //     }).then(canvas => {
  //       // Convert the composite canvas to PNG
  //       const screenshot = canvas.toDataURL('image/png')

  //       // Create download link
  //       const link = document.createElement('a')
  //       link.download = `screenshot-${Date.now()}.png`
  //       link.href = screenshot
  //       link.click()
  //       link.remove()

  //       setSessionData(prevState => ({
  //         ...prevState,
  //         screenshotModeActive: false
  //       }))
  //     }).catch(err => {
  //       console.error('Screenshot failed:', err)
  //       setSessionData(prevState => ({
  //         ...prevState,
  //         screenshotModeActive: false
  //       }))
  //     })
  //   }
  // }



  // Maybe... large, but images are upside down
  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {
  //     const { gl, scene, camera } = useThree()

  //     // First render the WebGL canvas
  //     gl.render(scene, camera)

  //     // Get the container element and its dimensions
  //     const container = document.getElementById('main-stage')
  //     const { width, height } = container.getBoundingClientRect()

  //     // Use html2canvas to capture the entire canvas container including HTML overlays
  //     html2canvas(container, {
  //       useCORS: true,
  //       allowTaint: true,
  //       backgroundColor: null,
  //       logging: false,
  //       width: width,
  //       height: height,
  //       scale: 1, // Keep scale at 1 for consistent capture
  //       onclone: (clonedDoc) => {
  //         const clonedContainer = clonedDoc.getElementById('main-stage')
  //         if (clonedContainer) {
  //           // Find all DREI HTML elements (adjust selector as needed)
  //           const dreiElements = clonedContainer.querySelectorAll('.drei-html')

  //           // Apply transform to the parent container of DREI elements
  //           dreiElements.forEach(element => {
  //             const parent = element.parentElement
  //             if (parent) {
  //               // Scale up the entire group while preserving positions
  //               parent.style.transform = 'scale(2)' // Adjust scale factor as needed
  //               parent.style.transformOrigin = 'center center'
  //               // Maintain relative positioning
  //               parent.style.position = 'relative'
  //             }
  //           })

  //           clonedContainer.style.width = `${width}px`
  //           clonedContainer.style.height = `${height}px`
  //         }
  //       }
  //     }).then(canvas => {
  //       const screenshot = canvas.toDataURL('image/png')

  //       const link = document.createElement('a')
  //       link.download = `screenshot-${Date.now()}.png`
  //       link.href = screenshot
  //       link.click()
  //       link.remove()

  //       setSessionData(prevState => ({
  //         ...prevState,
  //         screenshotModeActive: false
  //       }))
  //     }).catch(err => {
  //       console.error('Screenshot failed:', err)
  //       setSessionData(prevState => ({
  //         ...prevState,
  //         screenshotModeActive: false
  //       }))
  //     })
  //   }
  // }


  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {

  //     try {
  //       const container = document.getElementById('main-stage');
  //       html2canvas(container, {
  //         useCORS: true,
  //         allowTaint: true,
  //         foreignObjectRendering: true,
  //         logging: false,
  //         onclone: (document) => {
  //           // Optional: Any modifications to the cloned document before rendering
  //         }
  //       })
  //         .then(canvas => {
  //           const link = document.createElement('a');
  //           link.download = sceneData[sessionData.activeIndex].name + '.png';
  //           link.href = canvas.toDataURL('image/png', 1.0);
  //           link.click();
  //           link.remove();

  //         })
  //         .catch(error => {
  //           console.error('Screenshot failed:', error);
  //         }).finally(() => {
  //           setSessionData(prevState => ({
  //             ...prevState,
  //             screenshotModeActive: false
  //           }));
  //         });
  //     } catch (error) {
  //       console.error('Screenshot failed:', error);
  //     }
  //   }
  //   return null;
  // }
  function decodeBackgroundUrls(element) {
    const elements = element.getElementsByTagName('*');
    for (let el of elements) {
      const style = window.getComputedStyle(el);
      if (style.backgroundImage) {
        const decodedUrl = style.backgroundImage.replace(/&quot;/g, '"');
        el.style.backgroundImage = decodedUrl;
      }
    }
  }

  // ALMOST WORKING... RESUME HERE
  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {

  //     try {
  //       const container = document.getElementById('main-stage');
  //       console.log('container', container)
  //       decodeBackgroundUrls(container);
  //       console.log('container2', container)
  //       html2canvas(container, {
  //         useCORS: true,
  //         allowTaint: true,
  //         foreignObjectRendering: true,
  //         logging: false,
  //         onclone: (document) => {
  //           // Optional: Any modifications to the cloned document before rendering
  //         }
  //       })
  //         .then(canvas => {
  //           const link = document.createElement('a');
  //           link.download = sceneData[sessionData.activeIndex].name + '.png';
  //           link.href = canvas.toDataURL('image/png', 1.0);
  //           link.click();
  //           link.remove();

  //         })
  //         .catch(error => {
  //           console.error('Screenshot failed:', error);
  //         }).finally(() => {
  //           setSessionData(prevState => ({
  //             ...prevState,
  //             screenshotModeActive: false
  //           }));
  //         });
  //     } catch (error) {
  //       console.error('Screenshot failed:', error);
  //     }
  //   }
  //   return null;
  // }

  // const Screenshot = () => {
  //   if (sessionData.screenshotModeActive) {
  //     const { gl, scene, camera } = useThree()

  //     // First render the WebGL canvas
  //     //gl.render(scene, camera)

  //     // Get the container element and its dimensions
  //     const container = document.getElementById('main-stage')
  //     const { width, height } = container.getBoundingClientRect()

  //     // Use html2canvas to capture the entire canvas container including HTML overlays
  //     html2canvas(container, {
  //       useCORS: true,
  //       allowTaint: true,
  //       backgroundColor: null,
  //       logging: false,
  //       // Calculate the cropping dimensions (20% of original size)
  //       width: width * 0.2,
  //       height: height * 0.2,
  //       // Offset to center of screen (40% from left/top to get middle 20%)
  //       x: width * 0.4,
  //       y: height * 0.4,
  //       scale: window.devicePixelRatio,
  //       onclone: (clonedDoc) => {
  //         const clonedContainer = clonedDoc.getElementById('main-stage')

  //         if (clonedContainer) {
  //           // Find all DREI HTML elements (adjust selector as needed)
  //           const dreiElements = clonedContainer.querySelectorAll('.drei-html')
  //           // Apply transform to the parent container of DREI elements
  //           dreiElements.forEach(element => {
  //             const parent = element.parentElement
  //             if (parent) {
  //             }
  //           })
  //           clonedContainer.style.width = `${width * 0.2}px`
  //           clonedContainer.style.height = `${height * 0.2}px`
  //           clonedContainer.style.transform = 'scale(2)'
  //           //clonedContainer.style.transformOrigin = 'center center'
  //           clonedContainer.style.left = '50%'
  //           clonedContainer.style.top = '50%'
  //           clonedContainer.style.transform = 'translate(-50%, -50%)'
  //         }
  //       }
  //     }).then(canvas => {



  //       const screenshot = canvas.toDataURL('image/png')

  //       const link = document.createElement('a')
  //       link.download = `screenshot-${Date.now()}.png`
  //       link.href = screenshot
  //       link.click()
  //       link.remove()

  //       setSessionData(prevState => ({
  //         ...prevState,
  //         screenshotModeActive: false
  //       }))
  //     }).catch(err => {
  //       console.error('Screenshot failed:', err)
  //       setSessionData(prevState => ({
  //         ...prevState,
  //         screenshotModeActive: false
  //       }))
  //     })
  //   }
  // }



  const QuickScreenshot = () => {
    if (sessionData.quickScreenshotModeActive) {
      const { gl, scene, camera } = useThree()
      gl.render(scene, camera)
      const file = gl.domElement.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = sceneData[sessionData.activeIndex].name + '.png';
      link.href = file;
      link.click();
      link.delete;
      setSessionData(prevState => ({
        ...prevState,
        quickScreenshotModeActive: false
      }))
    }
  }


  // ************************************************************
  // TODO - SENSE CHANGE IN INDEX AND RESPOND....
  // ************************************************************

  useEffect(() => {
    //setTimeout(() => {
    onChange(sessionData.activeIndex)
    //}, "5");
    //onChange(sessionData.activeIndex)
  }, [sessionData.activeIndex]);

  // ************************************************************
  // END TODO - SENSE CHANGE IN INDEX AND RESPOND....
  // ************************************************************


  // function Loader() {
  //   const { active, progress, errors, item, loaded, total } = useProgress()
  //   return <Html center>{progress} % loaded</Html>
  // }

  // const customStyles = {
  //   content: {
  //     top: '50%',
  //     left: '50%',
  //     right: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     transform: 'translate(-50%, -50%)',
  //     width: '30%',
  //     borderRadius: '12px',
  //     textAlign: 'center',
  //     border: '0px',
  //     overflow: 'hidden'
  //   },
  // };


  let movieOpen = 0

  const hideMovie = () => {
    if (movieOpen) {
      anime({
        targets: '.video-float',
        translateX: 0,
        easing: 'easeInOutExpo'
      });
      movieOpen = 0
    }
    else {
      anime({
        targets: '.video-float',
        translateX: -580,
        easing: 'easeInOutExpo'
      });
      movieOpen = 1
    }
  }


  useEffect(() => {
    if (sessionData.playModeActive == 1) {
      ArrowKeysReact.config({
        left: () => {
          sessionData.activeIndex > 0 ? onChange(sessionData.activeIndex - 1) : null
        },
        right: () => {
          //onChange(sessionData.activeIndex + 1)
          sessionData.activeIndex + 1 < sceneData.length ? onChange(sessionData.activeIndex + 1) : null
        },
        up: () => {
          //onChange(sessionData.activeIndex - 1)
          sessionData.activeIndex > 0 ? onChange(sessionData.activeIndex - 1) : null
        },
        down: () => {
          sessionData.activeIndex + 1 < sceneData.length ? onChange(sessionData.activeIndex + 1) : null
        }
      })
    }
    else {
      ArrowKeysReact.config({
        left: () => { },
        right: () => { },
        up: () => { },
        down: () => { }
      })
    }
  }, [sessionData])

  // else 
  // {
  //   ArrowKeysReact.config({
  //   })    
  // }

  const activateSidebar = (id) => {
    const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
    el1.classList.add('active');
    var accordionPanels = document.querySelectorAll('.accordion__panel');
    for (var i = 0; i < accordionPanels.length; i++) {
      var panel = accordionPanels[i];
      if (panel.getAttribute("data-itemguid") === id) {
        panel.removeAttribute("hidden");
      }
      else {
        panel.setAttribute("hidden", "");
      }
    }
    const buttons = document.querySelectorAll('.accordion__button');
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];
      if (button.getAttribute("data-itemguid") === id) {
        button.setAttribute('aria-expanded', 'true');
        button.classList.add('last-accordion-button');
      }
      else {
        button.setAttribute('aria-expanded', 'false');
        button.classList.remove('last-accordion-button');
      }
    }
  }



  const RenderNotifications = (currentScene) => {

    let notificationItem = []
    let sceneInfo = currentScene.currentScene

    if (sceneInfo != undefined) {
      if (sceneInfo.viznodes != undefined) {
        //console.log('Viznodes are avail')
        for (let i = 0; i < sceneInfo.viznodes.length; i++) {
          //console.log(sceneInfo.viznodes[i])
          if (sceneInfo.viznodes[i].id == 8) {
            //console.log(sceneInfo.viznodes[i])
            notificationItem.push(sceneInfo.viznodes[i])
          }
        }
      }
    }




    return (
      notificationItem.map((vizItem, index) =>
        <div
          className={
            "notification rounded animate__animated animate__fadeIn animate__delay-2s " + vizItem.position.replace(' ', '-').toLowerCase()
          }
          key={vizItem.id + '-notification'}
          onClick={() => activateSidebar(vizItem.guid)}
          style={{
            backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : ''}`,
          }}
        >
          {/* <div className="text-gray-300 text-xl">{vizItem.position}</div> */}
          <div dangerouslySetInnerHTML={{ __html: vizItem.content }}></div>
        </div>
      )
    )
  }


  useEffect(() => {
    // console.log('SESSION DATA')
    // console.log(sessionData.playModeActive)

    if (sessionData.playModeActive == 1) {

    }

  }, [sessionData.playModeActive])



  const TogglePlayMode = () => {
    //console.log('GOT TOGGLE.... playing now')
    if (sessionData.playModeActive) {
      anime({
        targets: '.scene-edit-overlay.left',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 1000
      });
      anime({
        targets: '.scene-edit-overlay.right',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 1000
      });

      anime({
        targets: '#playModeNavigation',
        translateY: 0,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '#panel-story-control',
        translateY: 0,
        easing: 'easeInOutExpo',
        duration: 1000
      });

      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 0
      }))
    }
    else {
      //console.log('GOT TOGGLE.... CLOSE!')
      anime({
        targets: '.scene-edit-overlay.left',
        translateX: -340,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '.scene-edit-overlay.right',
        translateX: 340,
        easing: 'easeInOutExpo'
      });

      let navDelay = 1000

      if (!editMode) {
        navDelay = 3000
      }

      anime({
        targets: '#playModeNavigation',
        translateY: -170,
        easing: 'easeInOutExpo',
        delay: navDelay
      });

      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 1
      }))
    }
  }






  const RenderNavigation = (sceneData) => {



    return (
      <>
        {storyData.navigation == 'nav-mfg' ?
          <NavigationProcessChevron updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} ></NavigationProcessChevron>
          : (null)
        }

        {/* {storyData.navigation == 'nav1' ?
          <NavigationProcessChevron2 updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} />
          : (null)
        } */}
        {storyData.navigation == 'nav2' ?
          <>
            <NavigationTimelineMainSub updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} ></NavigationTimelineMainSub>
            {/* <div className="navigation-progress-wrapper">
              <div className="navigation-progress-meter" style={
                'width: ' + sessionData.activeIndex / sceneData.length * 100 + '%'
              } id="navigation-progress-meter"></div>
            </div> */}
          </>
          : (null)
        }
        {storyData.navigation == 'nav3' ?
          <NavigationTimelineSolo updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} ></NavigationTimelineSolo>
          : (null)
        }
        {storyData.navigation == 'nav4' ?
          <div className="navigation-segment scene-details">
            {sceneData[sessionData.activeIndex] ?
              <div className="nav-single-major">
                <div className="nav-heading">{sceneData[sessionData.activeIndex].name}</div>
                <div className="nav-subheading">The best Lorem Ipsum Generator in all the sea! Heave this scurvy copyfiller fer yar next adventure and cajol yar clients into walking the plank with ev'ry layout.</div>
                <div className="navigation-progress-wrapper">
                  <div className="navigation-progress-meter" style={
                    'width: ' + sessionData.activeIndex / sceneData.length * 100 + '%'
                  } id="navigation-progress-meter"></div>
                </div>
              </div>
              : (null)
            }
          </div>
          : (null)
        }
        {storyData.navigation == 'nav5' ?
          <div className="flex" id="interaction-wrapper">
            {sceneData.map((scene, index) => (
              <div
                key={scene.id}
                onClick={() => {
                  props.updateSceneFunction(index)
                }}
                className={`grow h-12 timeline-nav ${sessionData.activeIndex == index ? 'live-nav-on' : ''}`} >
                {scene.name}
              </div>
            ))}
          </div>
          : (null)
        }
      </>)
  }

  let editMode = 0
  let location = useLocation()

  if (!location.pathname.includes('/play/') && !location.pathname.includes('/share/')) {
    editMode = 1
  }

  useEffect(() => {

    // FRIENDLY URL --- HERE FOR PATH TO UPDATE!!!
    if (!location.pathname.includes('/play/') && !location.pathname.includes('/share/')) {
      editMode = 1
    } else {
      // Activate arrow keys
      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 1
      }))
    }

    if (editMode != 1) {
      // Update analytics...
      //console.log('UPDATE ANALYTICS')

      // const updateAnalytics = async () => {
      //   const storyRef = doc(db, 'stories', storyId)
      //   //console.log('NEW VIEW COUNT')
      //   //console.log(storyData.analyticsViewTotal)

      //   const res = await updateDoc(storyRef, {
      //     ...storyData,
      //     analyticsViewTotal: increment(1),
      //     analyticsLastViewed: serverTimestamp(),
      //   })

      // }
      // updateAnalytics()

      // const addAnalytics = async () => {
      //   const analyticsRef = await addDoc(collection(db, 'stories', storyId, 'analytics'), {
      //     storyId: storyId,
      //     createdAt: serverTimestamp(),
      //   })
      // }
      // addAnalytics()
    }

  }, [])

  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState("#0ca5e9")

  const override = {
    margin: "auto",
    position: "relative",
  }





  extend({ Water })

  //  function Ocean() {
  const Ocean = (props) => {
    const ref = useRef()
    const gl = useThree((state) => state.gl)
    const waterNormals = useLoader(THREE.TextureLoader, '/waternormals.jpeg')
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
    const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), [])
    const config = useMemo(
      () => ({
        textureWidth: 512,
        textureHeight: 512,
        waterNormals,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x064273,
        distortionScale: 3.7,
        fog: false,
        format: gl.encoding
      }),
      [waterNormals]
    )
    useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta / 2))
    return <water ref={ref} args={[geom, config]} position={props.position} rotation-x={-Math.PI / 2} />
  }





  const NavPath = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const togglePathDrawer = () => {
      setDrawerOpen(!isDrawerOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}
        <div className={`left-0 w-full  absolute top-1/2 h-screen -translate-y-full mt-[58px]   bg-zinc-900/50 shadow-lg z-40 transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        </div>


        <div className={`fixed  left-4 rounded-md w-4/12 absolute top-1/2 h-screen -translate-y-full mt-[72px]    bg-white shadow-lg z-50 transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <div className="p-4">

            <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
              <img src={storyData.imageStoryLogo} className="min-h-32  max-h-32 p-2" />
              <dl className="space-y-8 sm:space-y-6 p-2">
                <div className="text-zinc-800 py-4">
                  Please select a role or capability to learn more about DigitalEx can help your organization.
                </div>


                <div className="font-semibolxd text-xl text-zinc-800">
                  <img src="/util/app/b3-admin-org.svg" className="inline mr-2 h-12" /> Learn by Role
                </div>
                <PersonGrid sceneData={sceneData} />

                <div className="font-semibolxd text-xl text-zinc-800 mt-12">
                  <img src="/util/app/b3-admin-capability.svg" className="inline mr-2 h-12" /> Learn by Capability
                </div>

              </dl>
            </div>



            <button onClick={togglePathDrawer} className="absolute bottom-12 w-full bg-gray-800 hover:bg-gray-700 text-white rounded p-3 transition duration-300">
              Return to Experience
            </button>

          </div>
        </div>




        <div className={`fixed nav-standard mr-4 ml-4 org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'} inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0   shadow-lg rounded-md lg:shadow-none overflow-hidden`
        }
          style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : null}` }
          }
        >
          <div className="flex flex-col lg:flex-row w-full">

            <div className="flex justify-between items-center p-4  ">
              {storyData.imageStoryLogo != undefined && (
                <img src={storyData.imageStoryLogo} className="min-h-12  max-h-14" />
              )}
              <button onClick={togglePathDrawer} className="ml-2 ">
                <svg

                  className="w-6 h-6 text-gray-800 inline mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
              <div className="inline">
                Learn About
              </div>
              <button onClick={toggleMobileMenu} className="lg:hidden">
                <svg className="w-6 h-6 text-gray-800" />
              </button>
            </div>

            <div className={`p-4  ${isMobileMenuOpen ? 'flex-grow' : 'w-full flex'}`}>

              {sceneData.map((step, stepIdx) => (
                <div key={step.id + 'navwrap'}>
                  {!step.navOmitMain ?
                    <div
                      key={step.id + 'navitembottom'}
                      className="flex justify-between items-center"
                    >
                      <div
                        role="button"
                        className={`bg-gray-100 hover:bg-gray-200 rounded p-2   mr-4
                  transition duration-300 ${isMobileMenuOpen ? 'w-full text-md mb-1' : 'flex text-lg w-full'} 
                  ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ' '}
                  `}
                        key={stepIdx + '-nav'}
                        onClick={() => onChange(stepIdx)}
                        style={{
                          backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                          color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                          borderBottom: sessionData.activeIndex == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                        }}
                      >
                        {step.name}
                      </div>
                      {storyData.name.replace(/\s+/g, '-').toLowerCase() == 'oracle-scm' && stepIdx < sceneData.length - 1 ?
                        <div className="flex">
                          <img src="/util/app/nav-chevron-right-2.svg" className="opacity-20 h-10 mt-1" />
                        </div>
                        : (null)
                      }
                    </div>
                    : (null)}
                </div>
              ))}
            </div>

            {(!location.pathname.includes('/play/')) && (!location.pathname.includes('/share/')) && (
              <div className="p-4">
                {!isMobileMenuOpen ?
                  <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                    setSessionData(prevState => ({
                      ...prevState,
                      playModeActive: 0
                    }))
                  }}>
                    Close
                  </button>
                  : <div className="h-20"></div>
                }
              </div>
            )}
          </div>
        </div>

        {/* <button onClick={toggleMobileMenu} className="lg:hidden absolute top-4 right-4">
            <svg className="w-6 h-6 text-gray-800"   />
          </button>
          */}



      </>
    );
  };






  const NavMobile = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}




        <div className={`fixed nav-standard mr-4 ml-4 org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'} inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0   shadow-lg rounded-md lg:shadow-none overflow-hidden`
        }
          style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : null}` }
          }
        >
          <div className="flex flex-col lg:flex-row w-full">

            <div className="flex justify-between items-center p-4   lg:w-64">
              {storyData.imageStoryLogo != undefined && (
                <img src={storyData.imageStoryLogo} className="min-h-12  max-h-14" />
              )}
              <button onClick={toggleMobileMenu} className="lg:hidden">
                <svg className="w-6 h-6 text-gray-800" />
              </button>
            </div>

            <div className={`p-4  ${isMobileMenuOpen ? 'flex-grow' : 'w-full flex'}`}>

              {sceneData.map((step, stepIdx) => (
                <div key={step.id + 'navwrap'}>
                  {!step.navOmitMain ?
                    <div
                      key={step.id + 'navitembottom'}
                      className="flex justify-between items-center"
                    >
                      <div
                        role="button"
                        className={`bg-gray-100 hover:bg-gray-200 rounded p-2   mr-4
                  transition duration-300 ${isMobileMenuOpen ? 'w-full text-md mb-1' : 'flex text-lg w-full'} 
                  ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ' '}
                  `}
                        key={stepIdx + '-nav'}
                        onClick={() => onChange(stepIdx)}
                        style={{
                          backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                          color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                          borderBottom: sessionData.activeIndex == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                        }}
                      >
                        {step.name}
                      </div>
                      {storyData.name.replace(/\s+/g, '-').toLowerCase() == 'oracle-scm' && stepIdx < sceneData.length - 1 ?
                        <div className="flex">
                          <img src="/util/app/nav-chevron-right-2.svg" className="opacity-20 h-10 mt-1" />
                        </div>
                        : (null)
                      }
                    </div>
                    : (null)}
                </div>
              ))}
            </div>

            {(!location.pathname.includes('/play/')) && (!location.pathname.includes('/share/')) && (
              <div className="p-4">
                {!isMobileMenuOpen ?
                  <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                    setSessionData(prevState => ({
                      ...prevState,
                      playModeActive: 0
                    }))
                  }}>
                    Close
                  </button>
                  : <div className="h-20"></div>
                }
              </div>
            )}
          </div>
        </div>

        {/* <button onClick={toggleMobileMenu} className="lg:hidden absolute top-4 right-4">
            <svg className="w-6 h-6 text-gray-800"   />
          </button>
          */}



      </>
    );
  };




  const NavMobileStyle2 = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    // console.log('********************************')
    // console.log('USING 2.........')
    // console.log('********************************')

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}


        <div className={`fixed nav-standard mt-6 pb-4 h-20 org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'} inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0 `}
          style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : null}` }}
        >
          {/* Logo */}
          <div className="absolute top-0 left-0 right-0 flex justify-center items-center   z-10">
            {storyData.imageStoryLogo != undefined && (
              <img src={storyData.imageStoryLogo} className="min-h-12 max-h-20 absolute left-12 mt-12" />
            )}
          </div>

          <div className="flex flex-col lg:flex-row w-full pl-40 pb-2">
            {/* Navigation Items */}
            <div className={` ${isMobileMenuOpen ? 'flex-grow' : 'w-full flex'}`}>
              {sceneData.map((step, stepIdx) => (
                <div key={step.id + 'navwrap'} className="flex-1">
                  {!step.navOmitMain && (
                    <div
                      key={step.id + 'navitembottom'}
                      className="flex justify-between items-center "
                    >
                      <div
                        role="button"
                        className={`bg-gray-100 hover:bg-gray-200   pt-6 transition duration-300 ${isMobileMenuOpen ? 'w-full text-md mb-1' : 'flex text-lg w-full'
                          } ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ''}`}
                        key={stepIdx + '-nav'}
                        onClick={() => onChange(stepIdx)}
                        style={{
                          backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                          color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                          borderTop: sessionData.activeIndex == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                        }}
                      >
                        {step.name}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {(!location.pathname.includes('/play/')) && (!location.pathname.includes('/share/')) && (
              <div className="pr-2 pt-3">
                {!isMobileMenuOpen ? (
                  <button
                    className="p-4 w-full bg-gray-800 hover:bg-gray-700 text-white rounded pt-3 transition duration-300"
                    onClick={() => {
                      setSessionData(prevState => ({
                        ...prevState,
                        playModeActive: 0
                      }))
                    }}
                  >
                    Close
                  </button>
                ) : (
                  <div className="h-20"></div>
                )}
              </div>
            )}
          </div>

          <button onClick={toggleMobileMenu} className="lg:hidden absolute top-4 right-4">
            <svg className="w-6 h-6 text-gray-800" /* SVG for Close Icon */ />
          </button>
        </div>
      </>
    );
  };


  const NavMobileStyle3 = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    console.log('********************************')
    console.log('USING 3.........')
    console.log('********************************')

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}


        <div className={`fixed nav-standard mt-6 pb-4 h-20 org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'} inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0 `}
          style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : null}` }}
        >
          {/* Logo */}
          <div className="absolute top-0 left-0 right-0 flex justify-center items-center   z-10">
            {storyData.imageStoryLogo != undefined && (
              <img src={storyData.imageStoryLogo} className="min-h-12 max-h-20 absolute left-12 mt-12" />
            )}
          </div>

          <div className="flex flex-col lg:flex-row w-full pl-40 pb-2">
            {/* Navigation Items */}
            <div className={` ${isMobileMenuOpen ? 'flex-grow' : 'w-full flex'}`}>
              <div className="text-white text-3xl">{sceneData[sessionData.activeIndex].name} zzzzz</div>
              {sceneData.map((step, stepIdx) => (
                <div key={step.id + 'navwrap'} className="flex-1">
                  {!step.navOmitMain && (
                    <div
                      key={step.id + 'navitembottom'}
                      className="flex justify-between items-center "
                    >
                      <div
                        role="button"
                        className={`bg-gray-100 hover:bg-gray-200   pt-6 transition duration-300 ${isMobileMenuOpen ? 'w-full text-md mb-1' : 'flex text-lg w-full'
                          } ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ''}`}
                        key={stepIdx + '-nav'}
                        onClick={() => onChange(stepIdx)}
                        style={{
                          backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                          color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                          borderTop: sessionData.activeIndex == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                        }}
                      >
                        {step.name}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {(!location.pathname.includes('/play/')) && (!location.pathname.includes('/share/')) && (
              <div className="pr-2 pt-3">
                {!isMobileMenuOpen ? (
                  <button
                    className="p-4 w-full bg-gray-800 hover:bg-gray-700 text-white rounded pt-3 transition duration-300"
                    onClick={() => {
                      setSessionData(prevState => ({
                        ...prevState,
                        playModeActive: 0
                      }))
                    }}
                  >
                    Close
                  </button>
                ) : (
                  <div className="h-20"></div>
                )}
              </div>
            )}
          </div>

          <button onClick={toggleMobileMenu} className="lg:hidden absolute top-4 right-4">
            <svg className="w-6 h-6 text-gray-800" /* SVG for Close Icon */ />
          </button>
        </div>
      </>
    );
  };





  const NavDots = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}
        < div className={`fixed inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0   shadow-lg rounded-md lg:shadow-none overflow-hidden`
        }
          style={{
            backgroundColor: `${storyData.uiStyle ? '' : null}`
          }
          }
        >
          <div className="flex flex-col lg:flex-row w-full">


            {/* Navigation Items */}

            <div className={`p-4 mx-auto ${isMobileMenuOpen ? 'flex-grow' : 'w-fullxxx  text-center'}`}>

              {/* <div className="text-white text-3xl -mt-12 ">{sceneData[sessionData.activeIndex].name}</div> */}

              <div className=" flex space-x-4  ">
                {sceneData.filter(item => !item.navOmitMain).map((step, stepIdx) => (
                  <div
                    role="button"
                    className={`rounded-full h-6 w-6 mx-auto
                  transition duration-300 ${isMobileMenuOpen ? 'w-auto text-md mb-1' : 'w-12 mx-auto text-lg '} 
                  ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ' '}
                  `}
                    key={stepIdx + '-nav'}
                    onClick={() => onChange(stepIdx)}
                    style={{
                      backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                      color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                      border: sessionData.activeIndex == stepIdx ? '2px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '2px solid ' + storyData.uiStyle['nav-progress-meter']
                    }}
                  >

                  </div>
                ))}
              </div>
            </div>

            {/* Optional Close Button */}
            {/* {(!location.pathname.includes('/play/')) && (
              <div className="p-4">
                {!isMobileMenuOpen ?
                  <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                    setSessionData(prevState => ({
                      ...prevState,
                      playModeActive: 0
                    }))
                  }}>
                    Close
                  </button>
                  : <div className="h-20"></div>
                }
              </div>
            )} */}
          </div>
        </div >
      </>
    );
  };



  //export default YourComponent;

  const ShowChapters = () => {
    const [open, setOpen] = useState(true)

    return (
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Payment successful
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpen(false)}
                    >
                      Go back to dashboard
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }



  //   const getFormattedTime = (given_seconds) => {
  //     // function convertSecondstoTime() {
  //     //   given_seconds = 3685;

  //       const dateObj = new Date(given_seconds * 1000);
  //       hours = dateObj.getUTCHours();
  //       minutes = dateObj.getUTCMinutes();
  //       seconds = dateObj.getSeconds();

  //       timeString = hours.toString().padStart(2, '0')
  //           + ':' + minutes.toString().padStart(2, '0')
  //           + ':' + seconds.toString().padStart(2, '0');

  //       return timeString;
  // }

  function getFormattedTime(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + String(mins).padStart(2, '0') + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }


  const [isModalTOCOpen, setIsModalTOCOpen] = useState(false);
  const openTOCModal = () => setIsModalTOCOpen(true);
  const closeTOCModal = () => setIsModalTOCOpen(false);


  const [mediaIsPlaying, setMediaIsPlaying] = useState(true)

  const showTOC = () => {
    var els = document.getElementsByClassName('only-toc')
    Array.from(els).forEach((el) => {
      //el.classList.add('animate__animated')
      //el.classList.add('animate__fadeInUp')
      el.classList.remove('noshow')
    });
  }

  const closeTOC = () => {
    var els = document.getElementsByClassName('only-toc')
    Array.from(els).forEach((el) => {
      //el.classList.remove('animate__fadeInUp')
      //el.classList.add('animate__fadeOutDown')
      el.classList.add('noshow')
    });
  }

  const startExperience = () => {
    // Used to ensure that the audio / video starts when the user begins the experience
    onChange(1)
    setTimeout(() => {
      onChange(0)
    }, 1)
  }




  const [showWelcomeModal, setShowWelcomeModal] = useState(true)

  const ModalWelcome = () => {

    // available control variables
    const props = {
      percent: 13, // is require
      //colorSlice: "#00a1ff",
      colorCircle: "#111",
      fontColor: "#365b74",
      fontSize: "1.6rem",
      fontWeight: 400,
      size: 150,
      stroke: 10,
      //strokeBottom: 5,
      speed: 10,
      cut: 0,
      animationSmooth: '500ms ease-out',
      rotation: -90,
      //fill: "#000",
      unit: "%",
      textPosition: "0.35em",
      animationOff: false,
      //strokeDasharray: "10,1",
      inverse: false,
      round: true,
      number: false,
      linearGradient: ["#ffff00", "brown"],
      styles: {
        borderRadius: "50%",
        //boxShadow: "inset 0 0 25px 10px #a2caff"
      }
    }

    return (
      <>
        {/* <button
          className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Open regular modal
        </button> */}
        {showWelcomeModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  animate__delay-1s  animate__animated animate__fadeIn "
            >
              <div className="relative w-auto my-6 xs:my-0 sm:my-0 md:my-0 mx-auto max-w-5xl">
                {/*content*/}
                <div className="border-2 rounded-md shadow-lg relative flex flex-col w-full  outline-none   focus:outline-none border-zinc-900 bg-zinc-900
                 bg-[url('https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fb3-investor-welcome-4.jpg?alt=media&token=ef81b4cb-0d69-43ed-b398-be1aeef48dc5')] bg-cover bg-center 
                ">
                  {/*header*/}

                  {screenOrientation === 'landscape-primary' ?
                    <div className="  w-full p-9 
                  xs:p-4 sm:p-4 md:p-4 lg:p-4 xl:p-9
                  xs:pl-9 sm:pl-9 md:pl-9 lg:pl-9  
                  rounded-t-md">
                      <div className="text-4xl 
                    xs:text-xl sm:text-2xl md:text-2xl lg:text-3xl
                    xss:text-lg
                    xss:mt-10 xs:mt-10 md:mt-0 lg:mt-0 xl:mt-0 mt-0
                    font-base text-gray-50 flex">
                        <div className="flex-auto">
                          {storyData.name}
                        </div>

                      </div>
                    </div>
                    :
                    <div className="bg-zinc-800 w-full p-9 
                  xs:p-4 sm:p-4 md:p-4 lg:p-4 xl:p-9
                  xs:pl-9 sm:pl-9 md:pl-9 lg:pl-9
                  mt-16
                  rounded-t-md">
                      <div className="text-3xl 
                    xs:text-xl sm:text-2xl md:text-2xl lg:text-2xl
                    xss:text-lg
                    font-base text-gray-50 flex">
                        <div className="flex-auto">
                          {storyData.name}
                          <img src="/media/icon/b3-created-with.png" className="inline-block h-8
                        xs:h-6 sm:h-6 md:h-6 lg:h-8
                        self-end" />
                        </div>
                      </div>
                    </div>
                  }


                  {screenOrientation === 'landscape-primary' ?
                    <>
                      <div className="  p-5"

                      // style={{backgroundImage: `https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fb3-investor-welcome-2.jpg?alt=media&token=57ddf413-a0f3-41a9-a503-94d9bb86945f'`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                      >
                        <div className="relative p-4 w-full mr-6
                    xs:mr-0 sm:mr-0 md:mr-0 lg:mr-6  
                    ">
                          <div className="text-zinc-300 text-lg  leading-8 w-3/5">
                            This presentation runs on our no-code Brevit3 visual storytelling platform - designed to deliver stories that illustrate people, process and technology for sales, marketing  enablement.
                            <br /><br />
                            {/* <img src="https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fb3-screen-group.png?alt=media&token=f302ce6a-3cb4-4af1-a17e-215df610dad9"
                          className="w-full animate__animated animate__fadeIn h-56 animate__delay-1s"
                        />
                        <br /> */}
                            {/* We hope you enjoy the experience and look forward to hearing from you.<br /><br /> */}
                            Sincerely,<br />
                            Brevit3 Team



                            <img src="/util/app/b3-screen-rotate.svg" className="w-24
                        xxs:block xs:hidden sm:hidden 
                        md:hidden lg:hidden xl:hidden 
                        " />


                          </div>



                          <div className="flex gap-4 mt-12">
                            <div className="  animate__delay-1s    animate__animated animate__fadeIn
                  relative  p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                              onClick={() => {
                                startExperience()
                                setShowWelcomeModal(false)
                              }}>
                              <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                              <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                                <span className="relative text-white text-lg">
                                  <img src="/util/app/b3-admin-play.svg" class="h-5 -mt-1 mr-3 inline" />
                                  Start Experience</span>
                              </span>
                            </div>

                            <div className="inline   rounded-md mt-1">
                              <div className="text-zinc-200 text-2xl font-base">
                                6:10 <br /><div className="text-zinc-400 text-sm -mt-1 font-normal"> viewing time</div>
                              </div>
                              {/* <div>
                            <span className="text-zinc-300 text-base font-normal"> viewing time</span>
                            </div> */}
                            </div>
                          </div>




                          {/* AakDapu24NoaDj7E12CA */}

                          <div className="absolute right-0 mt-14"><a href="https://brevit3.com" target="_blank" >
                            <img src="/media/icon/b3-created-with.png" className="inline-block h-6
                        self-end" />
                          </a>
                          </div>


                        </div>







                      </div>

                    </>
                    :
                    <div className="flex items-start justify-between p-5  ">
                      <div className="relative p-4 flex-auto mr-6
                    xs:mr-0 sm:mr-0 md:mr-0 lg:mr-6">
                        <div className="text-zinc-400 text-lg xs:text-sm sm:text-base leading-8">
                          <img src="/util/app/b3-screen-rotate.svg" className="w-24 mx-auto" />
                          <br /><br />
                          Please rotate your device to the side to view this experience.  Thank you!
                        </div>
                      </div>
                    </div>
                  }






                </div>
              </div>
            </div>

            <div className=" fixed inset-0 z-40 bg-zinc-900"></div>
          </>
        ) : null}
      </>
    )
  }




  //  const [showOfflineModal, setShowOfflineModal] = useState(false)

  const ModalOffline = () => {

    return (
      <>
        {showWelcomeModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none"
              style={{ zIndex: 1000 }}
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl  animate__animated animate__fadeInUp animate__delay-2s">
                <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-zinc-900 outline-none focus:outline-none">
                  <div className="bg-zinc-800 w-full p-9 rounded-t-md">
                    <div className="text-3xl font-base text-gray-50 flex">
                      <div className="flex-auto">
                        You are Offline
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-between p-5  ">
                    <div className="relative p-4 flex-auto">
                      <div className="text-gray-200 text-lg leading-8">
                        You are currently offline.  Try connecting to the internet and refreshing your browser.
                      </div>
                    </div>
                  </div>

                  <div className="m-8 relative mt-12 p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                    onClick={() => {
                      window.location.reload()
                    }}>
                    <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                    <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                      <span className="relative text-white text-lg">Refresh</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black"
              style={{ zIndex: 999 }}
            ></div>
          </>
        ) : null}
      </>
    )
  }


  const screenOrientation = useScreenOrientation()

  const [showDocumentModal, setShowDocumentModal] = useState(false)

  const ModalDocuments = () => {


    const documentData = [
      {
        name: 'Brevit3 PDF Pitch Deck',
        link: 'https://drive.google.com/file/d/1uukCbaQsiKLqLbq7ZTE3j2WcJSCluQAS/view?usp=drive_link',
        icon: 'https://www.google.com',
        summary: 'Traditional pitch deck in PDF format.  Includes additional GTM details beyond what is shared in the interactive version that is running on our platform.',
        time: '10 min',
        previewImage: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fb3-pitch-deck-cover.png?alt=media',
      },
      {
        name: 'eBook - Remarkable Demo Blueprint',
        link: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2FRemarkable_Demo_Blueprint_Brunswick.pdf?alt=media&token=24aba82c-a9a3-4094-9216-62e9c256fb7d',
        icon: 'https://www.google.com',
        summary: 'This eBook is filled with high value content for our ICP.  It is a comprehensive guide to creating a remarkable demo - covering strategies to boost relevancy and engagement.  It includes a step-by-step guide to creating a demo that will leave a lasting impression.',
        time: '2 hours',
        previewImage: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2FBook-Cover-RDB-Remarkable-Demo-Blueprint-2.png?alt=media',
      },
    ]


    return (
      <>
        {showDocumentModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-5xl  animate__animated animate__fadeInUp ">
                {/*content*/}
                <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-zinc-900 outline-none focus:outline-none">
                  {/*header*/}

                  <div className=" w-full p-9 rounded-t-md">
                    <div className="text-3xl font-base text-gray-50 flex">
                      <div className="flex-auto">
                        Resources - eBook & Pitch Deck
                      </div>
                      <div className="flex-none">
                        <img src="/apple-touch-icon.png" className="inline-block h-8 w-8 self-end" />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-between p-5  ">

                    <div className="text-gray-200 text-lg leading-8">
                      {documentData.map((doc, index) => (
                        <div className="w-full  bg-black/25 p-6 rounded-md mb-6" key={doc.name + '' + index}>

                          <div className="flex  ">
                            <div className="flex-none w-44 h-44 bg-cover bg-center bg-no-repeat rounded-md" style={{ backgroundImage: `url(${doc.previewImage})` }}></div>
                            <div className="flex-auto ml-6 mr-6">
                              <div className="text-2xl text-gray-50">
                                {doc.name}
                              </div>
                              <div className="text-gray-400 text-base mt-2">
                                {doc.summary}
                              </div>
                            </div>
                            <div className="">
                              {/* <div className="w-64 bg-black/25 rounded-md border-1 border-zinc-300 p-2 cursor-pointer">
                                <img src="/util/app/b3-admin-download.svg" className="inline w-8 " /> download
                              </div> */}
                              <a class="bg-black/50 hover:bg-zinc-800 text-gray-100 py-2 px-4 rounded inline-flex items-center"
                                target="_blank"
                                href={doc.link}
                              >
                                <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                <span>Download</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>

                  </div>

                  <div className="mx-8 relative   p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                    onClick={() => {
                      setShowDocumentModal(false)
                    }}>
                    <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                    <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                      <span className="relative text-white text-lg">Close</span>
                    </span>
                  </div>


                </div>
              </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    )
  }



  // function CameraInfo({ setCameraInfo }) {
  //   const { camera } = useThree();

  //   const updateCameraInfo = () => {
  //     setCameraInfo({
  //       position: camera.position.toArray(),
  //       target: camera.getWorldDirection(new THREE.Vector3()).toArray()
  //     });
  //   };

  //   React.useImperativeHandle(ref, () => ({
  //     updateCameraInfo
  //   }));

  //   return null;
  // }

  // const handleGetCameraInfo = () => {
  //   cameraInfoRef.current.updateCameraInfo();
  // };


  if (dataLoading) {
    return (
      <div id="full-loader">
        <div className="flex h-screen">
          <div className="m-auto">
            <img src="/media/icon/Brevit3-Logo-Home.png" className="-mt-20" />
            <BarLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={250}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      </div>
    )
  }
  else {


    // const cameraDetailRef = useRef();

    // const getCameraPos = () => {
    //   //if (sessionData.screenshotModeActive) {
    //     //const { gl, scene, camera } = useThree()
    //     const { camera } = useThree();
    //     //gl.render(scene, camera)
    //     console.log('CAMERA POSITION')
    //     console.log(camera.position)
    //     return camera.position

    //     // const file = gl.domElement.toDataURL('image/png');

    //     // // UNCOMMENT FOR PRODUCTION
    //     // UploadStoryMap(file)

    //     // setSessionData(prevState => ({
    //     //   ...prevState,
    //     //   screenshotModeActive: false
    //     // }))
    //   //}
    // }



    return (
      <>
        <SceneContext.Provider value={[sceneData, setSceneData]}>
          <StoryContext.Provider value={[storyData, setStoryData]}>

            <SessionContext.Provider value={[sessionData, setSessionData]}>
              {/* <SessionContextProvider> */}
              <div {...ArrowKeysReact.events} tabIndex="1">

                <div id="main-stage-wrapper">
                  <div id="main-stage" ref={exportRef} className={storyData ? storyData.backgroundSky : ''}>
                    <Canvas style={{ height: "100vh", outline: "none" }}
                      id="canvas"
                      antialias={'false'}
                      colormanagement='true'
                      //colorManagement={'false'}
                      flat
                      camera={{
                        fov: 10,
                        near: 1,
                        far: 2400,
                        position: [0, 120, 600],
                        zoom: sessionData.modeVideo === 'newscaster' ? 0.5 : 1
                      }}>
                      <CameraControls
                        position={position}
                        target={target}
                        cameraOrbitOn={
                          orbitActive
                          //storyData.cameraOrbitOn
                        }
                        onCameraReady={handleCameraReady}
                      />

                      {sceneLoaded & storyLoaded ?
                        <AppStoryExperience
                          storyData={storyData}
                          setOrbitActive={setOrbitActive}
                        ></AppStoryExperience>
                        : (null)}
                      {storyData.backgroundModel == 'background_ocean' ?
                        <group>
                          <Ocean position={[0, storyData.backgroundHeight, 0]} />
                          <Sky scale={5000} turbidity={0.1} />
                        </group>
                        : null
                      }

                      {storyData.backgroundSkyCloud == 'lightCloud' ?
                        <group>
                          <Cloud position={[-80, 22, -85]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[-40, 18, -80]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[0, 16, -75]} speed={0.2} opacity={.5} />
                          <Cloud position={[42, 17, -65]} speed={0.2} opacity={0.5} />
                          <Cloud position={[92, 22, -60]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                        </group>
                        : null
                      }

                      {storyData.backgroundSkyCloud == 'heavyCloud' ?
                        <group>
                          <Cloud position={[-80, 22, -85]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[-40, 18, -80]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[-45, 21, -72]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[0, 16, -75]} speed={0.2} opacity={.5} />
                          <Cloud position={[42, 17, -65]} speed={0.2} opacity={0.5} />
                          <Cloud position={[37, 23, -65]} speed={0.2} opacity={0.5} />
                          <Cloud position={[68, 25, -85]} speed={0.2} opacity={0.5} />
                          <Cloud position={[92, 22, -60]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[112, 18, -65]} speed={0.2} opacity={0.5} />
                        </group>
                        : null
                      }
                      {storyData.backgroundSkyCloud == 'heavyCloudBackUp' ?
                        <group>
                          <Cloud position={[-110, 32, -125]} speed={0.2} opacity={.5} scale={[3, 3, 3]} />
                          {/* <Cloud position={[-90, 28, -140]} speed={0.2} opacity={.5} scale={[3, 3, 3]} /> */}
                          <Cloud position={[-55, 31, -92]} speed={0.2} opacity={.5} scale={[4, 4, 4]} />
                          <Cloud position={[0, 26, -105]} speed={0.2} opacity={.5} />
                          <Cloud position={[42, 27, -115]} speed={0.2} opacity={0.5} />
                          <Cloud position={[37, 33, -105]} speed={0.2} opacity={0.5} scale={[2, 2, 2]} />
                          <Cloud position={[68, 35, -95]} speed={0.2} opacity={0.5} />
                          <Cloud position={[92, 32, -100]} speed={0.2} opacity={.5} scale={[3, 3, 3]} />
                          <Cloud position={[112, 38, -105]} speed={0.2} opacity={0.5} />
                        </group>
                        : null
                      }

                      {storyData.name == 'xxxxxxxxxxx' ?
                        <group>
                          <ExpMeshDecal
                            meshType={1}
                            dimensions={[1, 1, 3]}
                            imageUrl={'/media/demo/logos/icon-kubernetes.png'}
                            matcapUrl={'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/models%2Fmatcap_2024_10_07T07_24_53_233Z.png?alt=media&token=836d9bf8-0684-4f1e-a105-d37ff05e7e8e'}
                            roundedEdgeRadius={0.1}
                            roundedEdgeSegments={5}
                            position={[-30, 10, 0]}
                            rotation={[Math.PI / 2, Math.PI / 4, 0]}
                            scale={[3, 3, 3]}
                            imagePosition={[0, .51, 0]}
                            imageRotation={[Math.PI / 2, 0, 0]}
                            imageScale={.9}
                          />
                          <ExpMeshDecal
                            meshType={1}
                            dimensions={[1, 1, 3]}
                            imageUrl={'/media/demo/logos/icon-bedrock.png'}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            roundedEdgeRadius={0.1}
                            roundedEdgeSegments={5}
                            position={[-40, 22, 0]}
                            rotation={[Math.PI / 2, Math.PI / 4, 0]}
                            scale={[3, 3, 3]}
                            imagePosition={[0, .51, 0]}
                            imageRotation={[Math.PI / 2, Math.PI, Math.PI / 1.328]}
                            imageScale={1}
                          />
                          <ExpMeshDecal
                            meshType={1}
                            dimensions={[1, 1, 3]}
                            imageUrl={'/media/demo/logos/icon-gcp.png'}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            roundedEdgeRadius={0.1}
                            roundedEdgeSegments={5}
                            position={[-50, 28, 0]}
                            rotation={[Math.PI / 2, Math.PI / 4, 0]}
                            scale={[3, 3, 3]}
                            imagePosition={[0, .51, 0]}
                            imageRotation={[Math.PI / 2, Math.PI, Math.PI / 1.328]}
                            imageScale={1}
                          />
                          <ExpMeshDecal
                            meshType={1}
                            dimensions={[1, 1, 3]}
                            imageUrl={'/media/demo/logos/icon-azure.png'}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            roundedEdgeRadius={0.1}
                            roundedEdgeSegments={5}
                            position={[-60, 22, 0]}
                            rotation={[Math.PI / 2, Math.PI / 4, 0]}
                            scale={[3, 3, 3]}
                            imagePosition={[0, .51, 0]}
                            imageRotation={[Math.PI / 2, Math.PI, Math.PI / 1.328]}
                            imageScale={1}
                          />
                          <ExpMeshDecal
                            meshType={1}
                            dimensions={[1, 1, 3]}
                            imageUrl={'/media/demo/logos/icon-openai.png'}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            roundedEdgeRadius={.5}
                            roundedEdgeSegments={5}
                            position={[-70, 10, 0]}
                            rotation={[Math.PI / 2, Math.PI / 4, 0]}
                            scale={[3, 3, 3]}
                            imagePosition={[0, .51, 0]}
                            imageRotation={[Math.PI / 2, Math.PI, Math.PI / 1.328]}
                            imageScale={1}
                          />


                          <ExpMeshArrow
                            arrowType={1}
                            dimensions={[0.05, 1, 0.2]}
                            position={[-2, 25, 0]}
                            color="#ff0000"
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            chevronAngle={Math.PI / 4}
                            scale={[10, 10, 10]}
                          />
                          <ExpMeshArrow
                            arrowType={2}
                            dimensions={[0.05, 1, 0.15]}
                            position={[0, 20, 0]}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            //color="#00ff00"
                            circularRadius={0.4}
                            scale={[10, 10, 10]}
                          />
                          <ExpMeshArrow
                            arrowType={3}
                            dimensions={[0.03, 1.5, 0.1]}
                            position={[2, 15, 0]}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            //color="#0000ff"
                            sketchiness={0.15}
                            scale={[10, 10, 10]}
                          />
                          <ExpMeshArrow
                            arrowType={4}
                            dimensions={[0.04, 1.2, 0.12]}
                            position={[4, 10, 0]}
                            matcapUrl={'/util/matcap/gray-metal-2_40.png'}
                            //color="#ffff00"
                            dashLength={0.1}
                            dashGap={0.05}
                            scale={[10, 10, 10]}
                          />


                        </group>
                        : null}


                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[-80, 38, -85]} speed={0.2} opacity={.5} scale={[2, 2, 2]} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[-40, 36, -80]} speed={0.2} opacity={.5} scale={[2, 2, 2]} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[0, 30, -75]} speed={0.2} opacity={.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[42, 32, -65]} speed={0.2} opacity={0.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[68, 44, -85]} speed={0.2} opacity={0.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[92, 38, -60]} speed={0.2} opacity={.5} scale={[2, 2, 2]} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[112, 32, -65]} speed={0.2} opacity={0.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[-126, 40, -65]} speed={0.2} opacity={.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <>
                          <Cloud position={[5, -80, 65]} speed={0.2} opacity={.5} />
                          <Cloud position={[-46, -80, 55]} speed={0.2} opacity={.5} />
                        </> :
                        null}

                      <perspectiveCamera
                        ref={cameraRef}
                        radius={(1200 + 600) / 4}
                        fov={35}
                        position={[-20, 40, 120]}
                        lookAt={[0, -30, 0]}
                        zoom={2}
                      />

                      {sessionData.screenshotModeActive ?
                        <Screenshot></Screenshot>
                        : (null)
                      }
                      {sessionData.quickScreenshotModeActive ?
                        <QuickScreenshot></QuickScreenshot>
                        : (null)}

                    </Canvas>


                    <RenderNotifications currentScene={sceneData[sessionData.activeIndex]}></RenderNotifications>

                    <ExpHTMLEcoCard
                      currentScene={sceneData[sessionData.activeIndex]}
                      thisIndex={sessionData.activeIndex}
                      updateSceneFunction={onChange}
                    ></ExpHTMLEcoCard>

                    <Offline>
                      <ModalOffline />
                    </Offline>

                  </div>
                </div>

                {!editMode && storyData.navigation == 'nav-mfg' ?
                  <div id="playModeNavigation" className='navigation-bar navigation-bottom mode-edit'
                    style={{ bottom: '-20px' }}
                  >
                    <RenderNavigation></RenderNavigation>
                  </div>
                  : (null)}

                {!editMode &&
                  storyData.navigation != 'nav-mfg' &&
                  storyData.navigation != 'playlist' &&
                  storyData.navigation != 'playlist-sidebar2' &&
                  storyData.navigation != 'dots' ?
                  <div
                    id="playModeNavigation"
                    className=" navigation-bar navigation-bottom mode-public animate__animated animate__fadeInUp animate__delay-3s"
                  >
                    {storyData.navigation == 'nav-1' || storyData.navigation == 'nav1' ?
                      <NavMobile
                        storyData={storyData}
                        sceneData={sceneData}
                        sessionData={sessionData}
                        location={location}
                        onChange={onChange}
                        TogglePlayMode={TogglePlayMode}
                      />
                      : null}

                    {storyData.navigation == 'path1' ?
                      <NavPath
                        storyData={storyData}
                        sceneData={sceneData}
                        sessionData={sessionData}
                        location={location}
                        onChange={onChange}
                        TogglePlayMode={TogglePlayMode}
                      />
                      : null}

                    {storyData.navigation == 'nav-bottom-2' ?
                      <NavDots
                        storyData={storyData}
                        sceneData={sceneData}
                        sessionData={sessionData}
                        location={location}
                        onChange={onChange}
                        TogglePlayMode={TogglePlayMode}
                      />
                      : (null)}
                    {/* {storyData.navigation == 'nav-bottom-3' ?
                      <NavDots
                        storyData={storyData}
                        sceneData={sceneData}
                        sessionData={sessionData}
                        location={location}
                        onChange={onChange}
                        TogglePlayMode={TogglePlayMode}
                      />
                      : (null)} */}
                  </div>
                  : (null)}



                {editMode && storyData.navigation == 'dots' ?
                  <div
                    id="playModeNavigation"
                    className=" navigation-bar navigation-bottom mode-public animate__animated animate__fadeInUp animate__delay-3s"
                  >
                    <NavDots
                      storyData={storyData}
                      sceneData={sceneData}
                      sessionData={sessionData}
                      location={location}
                      onChange={onChange}
                      TogglePlayMode={TogglePlayMode}
                    />
                  </div>
                  : (null)}


                {editMode ?
                  <>
                    <StoryEditOverlay
                      updateSceneFunction={onChange}
                      TogglePlayMode={TogglePlayMode}
                      setOrbitActive={setOrbitActive}
                      // CAMERA HERE....!
                      //getCamera={getCameraPos}
                      getCameraInfo={getCameraInfo}
                    />
                    {/* <div id="playModeNavigation" className='navigation-bar navigation-bottom mode-edit'>
                      <RenderNavigation sceneData={sceneData}></RenderNavigation>
                    </div> */}
                  </>
                  :
                  (null)
                }





                {/* {showTOC ?
                      <div className="absolute bottom-96 text-center  bg-gray-800 rounded m-auto w-7/8 left-0 right-0 text-white text-2xl p-4  "
                        id="playModeNavigation"
                      >
                        <div className="w-full grid grid-cols-3 gap-4 animate__animated animate__fadeIn">
                          {sceneData.map((scene, index) => (
                            <div className="bg-gray-800 w-64 hover:bg-gray-700 text-white rounded p-3 transition duration-300 cursor-pointer text-left"
                              onClick={() => {
                                onChange(index)
                              }}>
                              <div className="text-xl text-gray-100">
                                {scene.name}
                              </div>
                              <div className="text-lg text-gray-300">
                                {scene.description}
                              </div>

                            </div>
                          ))}
                        </div>
                      </div>
                      : null} */}

                {/* const [isModalOpen, setIsModalOpen] = useState(false);

  const openTOCModal = () => setIsModalTOCOpen(true);
  const closeTOCModal = () => setIsModalTOCOpen(false); */}






                {storyData.navigation == 'playlist' ?
                  <>

                    <div className="relative h-full w-full z-10 ">
                      <div className="absolute bottom-56 left-6 right-6 h-fit only-toc noshow animate__animated animate__fadeInUp ">
                        {/* <div className="absolute bottom-40 text-center  bg-gray-800 rounded m-auto w-7/8 left-0 right-0 text-white text-2xl p-4  "
                  id="playModeNavigation"
                > */}
                        <div className="w-full grid grid-cols-1 gap-1  p-6 rounded bg-zinc-900  ">
                          {sceneData.map((scene, index) => (
                            <div className={index == sessionData.activeIndex ? 'bg-white/5 flex w-full hover:bg-white/20 text-white rounded p-4 transition duration-300 cursor-pointer text-left'
                              :
                              'flex w-full hover:bg-white/10 text-white rounded p-4 transition duration-300 cursor-pointer text-left'
                            }
                              key={scene.name + 'scenetocindex'}
                              onClick={() => {
                                onChange(index)
                                //console.log('you ouyououuououou')
                                closeTOC()
                              }}>
                              {/* <img src="https://res.cloudinary.com/djdw2s79u/image/upload/ar_16:9,c_fit,w_900//v1717028086/Cloudinary-React/qy8qh7a3nswqjr8feq7r.png" className="inline mr-4 rounded h-12 "/> */}
                              <div className="w-3/12 text-xl   text-gray-100">
                                {index + 1} - {scene.name}
                              </div>
                              <div className="w-7/12 text-base text-gray-300">
                                {scene.description}
                              </div>
                              <div className="w-2/12 text-right text-base text-gray-300">
                                {scene.duration || '0:00'}
                              </div>
                            </div>
                          ))}
                          <div className="mx-auto rounded-md p-3 w-fit text-white bg-white/5  hover:bg-white/10 hover:cursor-pointer mt-2.5"
                            onClick={() => {
                              closeTOC()
                            }}>
                            <img src="/media/icon/noun-close-fff.svg" className="inline mr-2 h-6" />
                            close
                          </div>
                        </div>

                      </div>
                    </div>

                    <img src="/util/app/pc_mark.svg" className="absolute top-10 left-10 w-24" />

                    <div className="absolute bottom-4 text-center   bg-zinc-900 rounded-lg m-auto left-6 right-6 text-white text-2xl p-2  ">

                      <div className="w-full grid grid-cols-12 gap-6 animate__animated animate__fadeIn text-sm">

                        <div className="col-span-3 pr-5  bg-white/5  rounded-lg hover:bg-white/10 hover:cursor-pointer">
                          {sessionData.activeIndex - 1 >= 0 ?
                            <div className=" ">
                              <button className="flex w-full text-white rounded  p-4  transition duration-300
                               animate__animated animate__fadeIn h-max 
                              "
                                onClick={() => {
                                  onChange(sessionData.activeIndex - 1)
                                }}>
                                <div className="flex-none p-4">
                                  <img src="/util/app/b3-button-previous.svg" className="w-6" />
                                </div>
                                <div className="flex-auto text-left mt-1">
                                  <span className="text-xl font-semibold">Previous</span>
                                  <br />
                                  <div className="text-lg w-full mt-2">
                                    {sceneData[sessionData.activeIndex - 1].name}
                                  </div>
                                  <div className="text-sm w-full text-zinc-300">
                                    {sceneData[sessionData.activeIndex - 1].description}
                                  </div>
                                </div>
                              </button>
                            </div>
                            : null
                          }
                        </div>




                        <div className="col-span-6  flex-col   p-3 rounded">
                          <div className="  text-center text-base flex">
                            <div className="flex-none w-14 pr-6 text-right text-zinc-300 mt-1">

                              <div id="vidElapsed"
                                className="only-media animate__animated animate__fadeIn"
                              ></div>

                            </div>
                            <div className="flex-auto text-2xl font-semibold mt-2 mb-2">
                              {sceneData[sessionData.activeIndex].name || ''}
                            </div>
                            <div className="flex-none w-14 text-right text-base text-zinc-300 mt-1">

                              <div id="vidDuration"
                                className="only-media animate__animated animate__fadeIn"
                              ></div>

                            </div>
                          </div>



                          <div className="flex w-full mt-2 h-6">

                            <div className="overflow-hidden h-3 mt-1  flex-auto rounded bg-black/50 only-media noshow">
                              <div
                                id="vidProgress"
                                className="shadow-none bg-white  h-4 transition-all ease-linear duration-1000 "></div>
                            </div>

                          </div>
                          <div className="flex w-full mt-2 text-lg h-16">

                            <div className="flex-auto">

                              <div className="mx-auto  rounded-md p-3 w-fit bg-white/5  hover:bg-white/10 hover:cursor-pointer mt-2.5"
                                onClick={() => {
                                  //openTOCModal()
                                  showTOC()
                                }}>
                                <img src="/util/app/b3-admin-chapters.svg" className="h-6 inline mr-2" />
                                Contents
                              </div>
                              {/* <ExpTOC isOpen={isModalTOCOpen} onClose={closeTOCModal} /> */}

                              <div className="mx-auto rounded-md p-3 w-fit text-white bg-white/5  hover:bg-white/10 hover:cursor-pointer mt-2.5"
                                onClick={() => {
                                  closeTOC()
                                }}>
                                <img src="/media/icon/noun-close-fff.svg" className="inline mr-2 h-6" />
                                close
                              </div>


                            </div>




                            <div className=" bg-white/5  mx-auto rounded-full p-4 w-fit mt-1 mb-1  hover:bg-white/10 hover:cursor-pointer only-media noshow animate__animated animate__fadeIn"
                              onClick={() => {
                                emitVid({ stop: '1' })
                                //setMediaIsPlaying(false)
                              }}>

                              <img src="/util/app/b3-admin-pause.svg" className="w-6" />
                            </div>
                            {/* :
                              <div className=" bg-white/5  mx-auto rounded-full p-4 w-fit mt-1 mb-1  hover:bg-white/10 hover:cursor-pointer only-media noshow animate__animated animate__fadeIn"
                                onClick={() => {
                                  emitVid({ play: '0' })
                                  //setMediaIsPlaying(true)
                                }}>

                                <img src="/util/app/b3-admin-play.svg" className="w-6" />
                              </div>
                            } */}

                            <div className="flex-auto only-media noshow animate__animated animate__fadeIn">
                              <div className="mx-auto rounded-md p-3 w-fit  bg-white/5   hover:bg-white/10 hover:cursor-pointer mt-2.5"
                                onClick={() => {
                                  openTOCModal()
                                }}>
                                <img src="/util/app/b3-admin-volume.svg" className="h-6 inline mr-2" />
                                Volume
                              </div>
                              {/* <ExpTOC isOpen={isModalTOCOpen} onClose={closeTOCModal} /> */}
                            </div>

                          </div>



                        </div>



                        {/* <div className="bg-black/50 mx-auto rounded-md p-3 w-12   hover:bg-white/10 hover:cursor-pointer"
                              onClick={() => {
                                emitVid({ stop: '1' })
                              }}>
                              <img src="/util/app/b3-admin-pause.svg" className="w-8" />
                            </div> */}

                        <div className="col-span-3 pl-5  bg-white/5  rounded-lg hover:bg-white/10 hover:cursor-pointer">
                          {sessionData.activeIndex < sceneData.length - 1 ?
                            <div
                              id="nextSceneButton"
                              className=" ">
                              <button className="flex   w-full  text-white rounded p-4 transition duration-300
                               animate__animated animate__fadeIn   h-28
                              "
                                onClick={() => {
                                  onChange(sessionData.activeIndex + 1)
                                }}>
                                <div className="flex-auto text-left mt-1">
                                  <span className="text-xl font-semibold">Next</span>
                                  <br />
                                  <div className="text-lg mt-2">
                                    {sceneData[sessionData.activeIndex + 1].name}
                                  </div>
                                  <div className="text-sm w-full text-zinc-300">
                                    {sceneData[sessionData.activeIndex + 1].description}
                                  </div>
                                </div>
                                <div className="flex-none p-4 mt-5">
                                  <img src="/util/app/b3-button-next.svg" className="w-6" />
                                </div>
                              </button>
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                  </>
                  : null}


                {editMode &&
                  sessionData.modeVideo == 'fullscreen'
                  && storyData.navigation != 'playlist'
                  && storyData.navigation != 'playlist-sidebar2'
                  && storyData.navigation != 'dots'
                  ?
                  <>
                    {/* NavMobile = ({ storyData, sceneData, sessionData, location, onChange, TogglePlayMode }) */}
                    <div id="playModeNavigation"
                      className={`p-3 absolute transition ease-in-out duration-750 ${sessionData.playModeActive == 1 ? '-translate-y-full delay-1000' : 'translate-y-0 delay-100 duration-1000'}`}
                    >
                      {storyData.navigation == 'nav1' ?
                        <NavMobile
                          storyData={storyData}
                          sceneData={sceneData}
                          sessionData={sessionData}
                          location={location}
                          onChange={onChange}
                          TogglePlayMode={TogglePlayMode}
                        />
                        : null}
                      {storyData.navigation == 'path1' ?
                        <NavPath
                          storyData={storyData}
                          sceneData={sceneData}
                          sessionData={sessionData}
                          location={location}
                          onChange={onChange}
                          TogglePlayMode={TogglePlayMode}
                        />
                        : null}
                      {storyData.navigation == 'nav-bottom-2' ?
                        <NavMobileStyle2
                          storyData={storyData}
                          sceneData={sceneData}
                          sessionData={sessionData}
                          location={location}
                          onChange={onChange}
                          TogglePlayMode={TogglePlayMode}
                        />
                        : (null)}
                    </div>
                  </>
                  :
                  (null)
                }
              </div>


              {sessionData.playModeActive == 1 ?
                <>
                  {storyData.navigation == 'playlist-sidebar2' ?
                    <>


                      {/* <div className="bg-slate-200 w-[600px] h-[400px] relative"> */}
                      {/* <div className="absolute w-1/3  h-fit
                        text-center
                         m-auto left-0 right-0 top-0 bottom-0 bg-zinc-900 text-gray-100 p-12">
                          <div className="text-2xl">
                          Welcome Details Go Here
                          </div>
                          <div className="text-gray-300 mt-12">
                          Some info here to welcome people to the experience
                          </div>
                          <div className="w-content p-2 rounded bg-black rounded mt-12"
                          onClick={() => { startExperience() }}
                          >
                          Start Experience
                          </div>
                        </div>
                        </div> */}
                      {/* </div> */}
                      <ModalWelcome />


                      <div className="p-3 px-4 pr-5 justify-center   items-center  z-10 absolute   hover:scale-105 hover:text-white right-6 bottom-6  scale-100 transition-all ease-in-out duration-100
                rounded-md text-gray-50 hover:cursor-pointer
                
                "
                        style={{ backgroundColor: '#444353' }}
                        onClick={() => {
                          setShowDocumentModal(true)
                        }}>
                        <img src="/util/app/b3-admin-docs.svg" className="w-8 inline mr-3 text-sm" /> eBook &amp; Pitch Deck
                      </div>

                      <ModalDocuments />

                      {/* <CompHSChat /> */}


                      <div className="p-4 justify-center items-center z-10 absolute top-2 left-1.5 bottom-2 
                      animate__animated  animated__fadeIn  h-full">
                        <div className="bg-black overflow-hidden rounded-md 
                        w-[8rem]
                        sm:w-[10rem]
                        md:w-[12rem] 
                        lgmd:w-[16rem]
                        lg:w-[20rem]
                        xl:w-[24rem]
                        2xl:w-[28rem]
                        "
                        //style={{ width: '25%' }}
                        //style={{ width: '480px' }}
                        >

                          {storyData.imageStoryLogo != undefined && (
                            <img src={storyData.imageStoryLogo} className=" h-5 lg:h-6 xl:h-8 2xl:h-10 px-4 mt-4 mb-4" />
                          )}
                          {/* <div className="h  w-full mx-auto"> */}
                          {sceneData[sessionData.activeIndex].video != undefined ?
                            <div
                            //style={{marginTop: '-36px'}}
                            //className="overflow-hidden"
                            >
                              <div className="animate__animated  animated__fadeIn overflow-hidden
                                              h-[400px]
                                              sm:h-[140px]
                                              md:h-[180px]
                                              lgmd:h-[240px]
                                              lg:h-[300px]
                                              xl:h-[360px]
                                              2xl:h-[400px]
                              "
                                style={{
                                  maxHeight: '400%',
                                  maxWidth: '200%',
                                  width: '200%',
                                  //height: '200%', 
                                  //height: '200%',
                                  marginLeft: '-50%',
                                  //marginTop: '-36px',
                                  overflow: 'hidden'
                                }}
                              >
                                <ReactPlayer
                                  url={sceneData[sessionData.activeIndex].video}
                                  width='100%'
                                  height='auto'
                                  controls={false}
                                  className="animate__animated  animated__fadeIn"
                                  ref={vidRef}
                                  //muted={true}
                                  muted={muted}
                                  playsinline={true}
                                  progressInterval={500}
                                  playing={videoPlaying}
                                  onEnded={() => {
                                    sessionData.activeIndex + 1 < sceneData.length ? onChange(sessionData.activeIndex + 1) : null
                                  }}
                                  onReady={(current) => {
                                    //document.getElementById("videoDuration").innerHTML = getFormattedTime(current.getDuration())
                                    //console.log('TIME for ' + sceneData[sessionData.activeIndex].name + ' .... ' + getFormattedTime(current.getDuration()))
                                  }}
                                  onPlay={() => {
                                  }}
                                  onProgress={(progress) => {
                                    //document.getElementById("videoProgress").innerHTML = getFormattedTime(progress.playedSeconds)
                                    {/* <div className="flex justify-between px-4 mt-2 text-sm h-6 text-gray-600 ">
                                          <span id="videoProgress"></span>
                                          <span id="videoDuration"></span>
                                        </div> */}
                                    document.getElementById("videoProgressMeter").style.width = progress.played * 100 + '%'
                                  }}
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nofullscreen",
                                      },
                                    },
                                  }}
                                />
                              </div>
                              <div className="px-0 mt-0 animate__animated animate__fadeIn " key={'player-video'}>
                                <div className="bg-zinc-900 h-2 rounded-full hover:cursor-pointer" id="videoProgressWrapper" onClick={handleProgressClick}>
                                  <div className="bg-zinc-600 h-2 transition-all ease-linear duration-500" id="videoProgressMeter"></div>
                                </div>
                              </div>
                            </div>
                            :
                            <>
                              <div className="h-96 w-full rounded-md"
                                style={
                                  {
                                    backgroundImage: 'url(/media/investor/b3-office-bg.png)',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                  }
                                }>
                              </div>

                              <div className="px-0 mt-0" key={'player-no-video'}>
                                <div className="bg-black/50 h-2 rounded-full">
                                  <div className=" h-2   transition-all ease-linear duration-1000 "></div>
                                </div>
                                <div className="flex justify-between mt-2 text-sm h-6 text-gray-600 ">
                                  <span ></span>
                                  <span ></span>
                                </div>
                              </div>
                            </>
                          }



                          <div className="bg-black/50 py-2 rounded-md  ">
                            <div className="  grid grid-cols-5 gap-1 mx-4 py-2">
                              <div>
                                {sessionData.activeIndex > 0 ?
                                  <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn " onClick={() => {
                                    onChange(sessionData.activeIndex - 1)
                                  }
                                  }>
                                    <img src="/util/app/b3-button-previous.svg" className="w-8" />
                                  </div>
                                  :

                                  <div className="  mx-auto rounded-md p-3 w-12    animate__animated animate__fadeIn " onClick={() => {
                                    //onChange(sessionData.activeIndex - 1)
                                  }
                                  }>
                                    <img src="/util/app/b3-button-previous.svg" className="w-8 opacity-10" />
                                  </div>
                                }

                              </div>



                              {sceneData[sessionData.activeIndex].video != undefined ?
                                <>
                                  {!videoPlaying ?
                                    <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                      onClick={() => {
                                        setVideoPlaying(true)
                                      }}>
                                      <img src="/util/app/b3-admin-play.svg" className="w-8" />
                                    </div>
                                    :
                                    <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                      onClick={() => {
                                        setVideoPlaying(false)
                                      }}>
                                      <img src="/util/app/b3-admin-pause.svg" className="w-8" />
                                    </div>
                                  }
                                </>
                                :
                                <div></div>
                              }




                              <div>
                                {sessionData.activeIndex + 1 < sceneData.length ?
                                  <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                    onClick={() => {
                                      onChange(sessionData.activeIndex + 1)
                                    }
                                    }>
                                    <img src="/util/app/b3-button-next.svg" className="w-8" />
                                  </div>
                                  : null}
                              </div>

                              <div className="col-span-2">
                                <div className="bg-black/50 mx-auto rounded-md p-3 w-full text-center text-gray-100 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                  onClick={toggleMute}>
                                  {muted ? 'unmute' : 'mute'}
                                </div>
                              </div>


                              {/* <div>
                                <div className=" mx-auto rounded-md py-3   hover:cursor-pointer  animate__animated animate__fadeIn text-gray-100 text-sm"
                                  onClick={() => {
                                  }
                                  }>
                                </div>
                              </div> */}

                            </div>



                            <div className="w-full 
                       overflow-y-scroll gap-1  sidebar-playlist-height  scroll-smooth"
                              id="scrollPlaylist"
                            >
                              {sceneData.map((scene, index) => (
                                <div className="px-4"
                                  key={scene.name + 'scenetocindex'}
                                >
                                  <div className={index == sessionData.activeIndex ?
                                    'bg-white/10 flex w-full hover:bg-white/20 text-white rounded   text-sm   cursor-pointer text-left rounded-md px-6  py-3'
                                    :
                                    'flex w-full hover:bg-white/10 text-gray-200 rounded  text-sm   cursor-pointer text-left px-6 py-3'
                                  }
                                    key={scene.name + 'scenetocindex'}
                                    onClick={() => {
                                      onChange(index)
                                    }}>
                                    <div className={index == sessionData.activeIndex ?
                                      'w-6  text-white '
                                      :
                                      'w-6   text-gray-300'
                                    }
                                    >
                                      <div className=" text-gray-500 inline">{index + 1}.</div>
                                    </div>
                                    <div className="w-9/12 text-sm text-gray-300">
                                      {scene.name}
                                    </div>
                                    {/* <div className="w-7/12 text-base text-gray-300">
                          {scene.description}
                        </div> */}
                                    <div
                                      className={index == sessionData.activeIndex ?
                                        'w-2/12 text-right text-sm text-gray-50'
                                        :
                                        'w-2/12 text-right text-sm text-gray-600'
                                      }
                                    >
                                      {scene.duration || '--'}
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {sessionData.playModeActive != 1 ?
                                <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                                  setSessionData(prevState => ({
                                    ...prevState,
                                    playModeActive: 0
                                  }))
                                }}>
                                  Close
                                </button>
                                : null}

                            </div>
                          </div>

                          <div className="w-full grid grid-cols-2 pl-8 pr-4 py-6 gap-1 bg-zinc-900  ">

                            <div className=" ">
                              <h2 className="text-xl sm:text-lg text-gray-50 font-semibol ">John Brunswick</h2>
                              <p className="text-gray-400 text-sm  ">Founder &amp; CEO, Brevit3</p>
                            </div>

                            <div className="text-xs text-gray-50 text-left  flex  justify-end gap-1">

                              <div className="bg-black/20 rounded-md p-3 h-12 hover:bg-white/10 hover:cursor-pointer  ">
                                {/* <img src="/util/app/b3-admin-send.svg" className="h-6 mx-auto" /> */}
                                <a href="https://calendly.com/brevit3/45min" target="_blank">
                                  <img src="/util/app/b3-admin-calendly.png" className="h-6 mx-auto inline mr-2" />
                                  Meet
                                </a>
                              </div>


                              <div className="bg-black/20 rounded-md p-3 h-12 hover:bg-white/10 hover:cursor-pointer  ">
                                <a href="https://www.linkedin.com/in/johnbrunswick/" target="_blank">
                                  <img src="/util/app/b3-logo-linkedin.png" className="h-6 mx-auto mr-2 inline " />
                                  Connect
                                </a>
                              </div>


                            </div>
                          </div>




                        </div>

                      </div>
                    </>
                    // </div>
                    : null}
                </>
                : null}

            </SessionContext.Provider>
            {/* </SessionContextProvider> */}

          </StoryContext.Provider>
        </SceneContext.Provider >





      </>

    );



  }
};

export default AppStoryPlayer;

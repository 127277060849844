import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    setDoc,
} from 'firebase/firestore'
import { Listbox, Transition } from '@headlessui/react'
import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'
import Slider from 'react-input-slider'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Menu, Combobox, Dialog, Popover } from '@headlessui/react'

// import { GridSelect } from './CompGridSelect'
import GridSelect from './CompGridSelect'

const ConfigWebView = ({ webView, storyId }) => {

    const [url, setUrl] = useState(webView.url)
    const [personaWrapperColor, setPersonaWrapperColor] = useState(webView.wrapperColor || '#000000')

    // Add this array of color options
    const colorOptions = [
        '#000000',  // Pure black - most common
        '#FFFFFF',  // Pure white
        '#C0C0C0',  // Silver/gray
        '#424242',  // Space gray (Apple's version)
        '#1C2938'   // Midnight (dark blue-black)
      ];
    const [storyData, setStoryData] = useContext(StoryContext)
    const [sceneData, setSceneData] = useContext(SceneContext)
    const [sessionData, setSessionData] = useContext(SessionContext)
    const [cardFaceCamera, setCardFaceCamera] = useState(webView.faceCamera || false)
    const [cardScale, setCardScale] = useState(webView.scale || 1)

    const [personaAnimation, setPersonaAnimation] = useState(webView.animation || 'none')
    const [personaDelay, setPersonaDelay] = useState(webView.animationDuration || 1)
    const [personaPositionXY, setPersonaPositionXY] = useState({ x: webView.pos.x || 0, y: webView.pos.y || 0 })
    const [personaSize, setPersonaSize] = useState(webView.size)
    const [personaWrapperStyle, setPersonaWrapperStyle] = useState(webView.wrapperStyle || 'default')

    console.log(webView)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const animationStyles = [
        { id: 1, name: 'none', displayName: 'None' },
        { id: 2, name: 'bounceIn', displayName: 'Bounce In' },
        { id: 3, name: 'fadeIn', displayName: 'Fade In' },
        { id: 4, name: 'fadeInDown', displayName: 'Fade In Down' },
        { id: 5, name: 'fadeInLeft', displayName: 'Fade In Left' },
        { id: 6, name: 'fadeInRight', displayName: 'Fade In Right' },
        { id: 7, name: 'fadeInUp', displayName: 'Fade In Up' },
        { id: 8, name: 'zoomIn', displayName: 'Zoom In' },
    ]

    const updateWebView = async (e) => {
        let cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

        // Ensure the URL has a proper protocol
        let updatedUrl = url.trim()
        if (!updatedUrl.startsWith('http://') && !updatedUrl.startsWith('https://')) {
            updatedUrl = 'https://' + updatedUrl
        }

        cloneVizActive.forEach(item => {
            if ((webView.guid == item.guid)) {
                item.url = updatedUrl
                item['pos'] = personaPositionXY
                item['size'] = personaSize
                item['animationDuration'] = personaDelay
                item['animation'] = personaAnimation
                item['faceCamera'] = cardFaceCamera
                item['scale'] = cardScale
                item['wrapperStyle'] = personaWrapperStyle
                item['wrapperColor'] = personaWrapperColor
            }
        })

        let activeSceneId = sceneData[sessionData.activeIndex].id
        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

        updateDoc(sceneRef, {
            viznodes: cloneVizActive
        })

        // Update the local state with the potentially modified URL
        setUrl(updatedUrl)
    }

    const faceCameraHandler = () => {
        setCardFaceCamera(!cardFaceCamera)
    }

    useEffect(() => {
        if (webView.pos.x !== personaPositionXY.x || webView.pos.y !== personaPositionXY.y) {
            updateWebView()
        }
    }, [personaPositionXY])

    useEffect(() => {
        updateWebView();
    }, [personaWrapperStyle, personaSize, personaWrapperColor])

    return (
        <form onSubmit={(e) => {
            e.preventDefault()
            updateWebView()
        }}>
            <div className="sm:col-span-2 mt-4 mr-1">

                <div className="mt-2.5 text-gray-400 text-sm ml-1 mb-4">
                    Enter the URL below. If you don't include http:// or https://, https:// will be added automatically.
                </div>

                <div className="mt-2.5">
                    <textarea
                        rows={2}
                        autoComplete="off"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        type="text"
                        name="url"
                        id="url"
                        placeholder='url'
                        className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
      text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
      focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    />
                </div>


                {url.includes('tableau') ?
                    <div className="animate__animated animate__fadeIn ">
                        <img src="/util/ecosystem/tableau-icon.svg" className="w-12 p-2 mt-1  inline" /> Tableau URL Detected
                    </div>
                    :
                    null
                }

                <div className='grid grid-cols-4 gap-4 w-full mt-6'>
                    <div className='col-span-4 text-gray-300 text-sm'>
                        Device Size Presets
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => setPersonaSize({ width: 1366, height: 768 })}
                            className={`w-full h-10 ${personaSize.width === 1366 && personaSize.height === 768 ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Laptop M
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => setPersonaSize({ width: 1920, height: 1080 })}
                            className={`w-full h-10 ${personaSize.width === 1920 && personaSize.height === 1080 ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Laptop L
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => setPersonaSize({ width: 390, height: 844 })}
                            className={`w-full h-10 ${personaSize.width === 390 && personaSize.height === 844 ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Phone
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => setPersonaSize({ width: 1366, height: 1024 })}
                            className={`w-full h-10 ${personaSize.width === 1366 && personaSize.height === 1024 ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Tablet
                        </div>
                    </div>
                </div>







                <div className='grid grid-cols-5 gap-4 w-full mt-6'>
                    <div className=' col-span-1  text-sm text-gray-300 ml-1'>Width </div>
                    <div className='col-span-3'>
                        <Slider
                            axis="x"
                            xmin={100}
                            xmax={2600}
                            xstep={10}
                            xreverse={false}
                            x={personaSize.width}
                            onChange={({ x }) => setPersonaSize(state => ({ ...state, width: x }))}
                            styles={{
                                active: {
                                    backgroundColor: '#333'
                                },
                                track: {
                                    backgroundColor: '#1b1e21'
                                }
                            }}
                        />
                    </div>
                    <div className='text-gray-300 col-span-1 text-sm'>{personaSize.width}</div>
                </div>

                <div className='grid grid-cols-5 gap-4 w-full mb-2'>
                    <div className=' text-gray-300  col-span-1  text-sm ml-1'>Height </div>
                    <div className='col-span-3'>
                        <Slider
                            axis="x"
                            xmin={100}
                            xmax={2600}
                            xstep={10}
                            xreverse={false}
                            x={personaSize.height}
                            onChange={({ x }) => setPersonaSize(state => ({ ...state, height: x }))}
                            styles={{
                                active: {
                                    backgroundColor: '#333'
                                },
                                track: {
                                    backgroundColor: '#1b1e21'
                                }
                            }}
                        />
                    </div>
                    <div className='text-gray-300 col-span-1 text-sm'>{personaSize.height}</div>
                </div>


                <div className='grid grid-cols-4 gap-4 w-full mt-6'>
                    <div className='col-span-4 text-gray-300 text-sm'>
                        Device Bezel
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => {
                                setPersonaWrapperStyle('default');
                                //updateWebView();
                            }}
                            className={`w-full h-10 ${personaWrapperStyle === 'default' ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Default
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => {
                                setPersonaWrapperStyle('phone');
                                //updateWebView();
                            }}
                            className={`w-full h-10 ${personaWrapperStyle === 'phone' ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Phone A
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => {
                                setPersonaWrapperStyle('phone2');
                                //updateWebView();
                            }}
                            className={`w-full h-10 ${personaWrapperStyle === 'phone2' ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Phone B
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <div
                            onClick={() => {
                                setPersonaWrapperStyle('tablet');
                                //updateWebView();
                            }}
                            className={`w-full h-10 ${personaWrapperStyle === 'tablet' ? 'bg-sky-500/80' : 'bg-white/5 hover:bg-white/10'} text-sm cursor-pointer text-center pt-2.5 rounded`}>
                            Tablet
                        </div>
                    </div>

                </div>



                <div className='grid grid-cols-5 gap-4 w-full mt-6'>
 
                    {colorOptions.map((color) => (
                        <div key={color} className='  px-3'>
                            <div
                                onClick={() => {
                                    setPersonaWrapperColor(color);
                                    updateWebView();
                                }}
                                className={`w-full h-8 w-8 rounded-full  border-2 border-zinc-900 cursor-pointer   ${personaWrapperColor === color ? 'outline outline-offset-2 outline-4 outline-sky-500' : ' '
                                    }`}
                                style={{ backgroundColor: color }}
                                title={color}
                            />
                        </div>
                    ))}
                </div>




                <div className="w-full mt-8 relative">
                    <div className='grid grid-cols-2 gap-0'>
                        <div className="flex">
                            <div className="ml-1 text-sm leading-6 mr-2">
                                <p id="candidates-description" className="text-gray-300 text-sm mt-2">
                                    Always Face
                                </p>
                            </div>
                            <div className=" h-6 items-center mt-2">
                                <input
                                    id="faceCamera"
                                    aria-describedby="faceCamera"
                                    name="faceCamera"
                                    type="checkbox"
                                    checked={cardFaceCamera}
                                    onChange={faceCameraHandler}
                                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                />
                            </div>
                        </div>
                        <div className=''>
                            <div className='grid grid-cols-5 gap-0'>
                                <div id="" className="text-gray-300 col-span-2 text-sm mt-2 text-right mr-2">
                                    Scale
                                </div>

                                <div className='grid grid-cols-10 gap-0 col-span-3 ml-2'>
                                    <div className='col-span-3  p-2  text-center  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                                        onClick={(e) =>
                                            cardScale > 0 ? setCardScale(cardScale - .25) : 0
                                        }
                                    >-</div>
                                    <div className='col-span-4'>
                                        <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
                      text-white   sm:text-sm sm:leading-6 text-center'>
                                            {cardScale}
                                        </div>
                                    </div>
                                    <div className='col-span-3 p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                                        onClick={(e) => setCardScale(cardScale + .25)}
                                    >+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className='flex w-full mb-6'>

                    <div className='flex w-1/5 mr-4 text-gray-300 pt-5  ml-1  text-sm pr-3'>
                        Animation
                    </div>
                    <div className='flex-auto'>
                        <div className='grid grid-cols-5 gap-3 mt-3  '>
                            <div className='col-span-3'>
                                <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                                    {({ open }) => (
                                        <>
                                            <div className="relative  ">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6 hover:bg-white/10">
                                                    <span className="block truncate">{personaAnimation.displayName || 'none'} &nbsp;</span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </span>
                                                </Listbox.Button>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {animationStyles.map((person) => (
                                                            <Listbox.Option
                                                                key={person.id}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={person}
                                                            >
                                                                {({ cardAnimation, active }) => (
                                                                    <>
                                                                        <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                            {person.displayName}
                                                                        </span>
                                                                        {cardAnimation ? (
                                                                            <span
                                                                                className={classNames(
                                                                                    active ? 'text-white' : 'text-sky-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            <div className='col-span-2'>
                                <div className='grid grid-cols-10 gap-0'>
                                    <div className='col-span-3  p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                                        onClick={(e) => personaDelay > 0 ? setPersonaDelay(personaDelay - 1) : 0}
                                    >-</div>
                                    <div className='col-span-4'>
                                        <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
text-gray-300   sm:text-sm sm:leading-6 text-center'>
                                            {!personaAnimation.displayName ? 0 : personaDelay}
                                        </div>
                                    </div>
                                    <div className='col-span-3 p-2 font-bold cursor-pointer text-center bg-white/5 hover:bg-white/10 rounded'
                                        onClick={(e) => setPersonaDelay(personaDelay + 1)}
                                    >+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full slider-parent mt-3'>
                    <GridSelect
                        activeNode={webView.guid}
                        viznodes={sceneData[sessionData.activeIndex].viznodes}
                        setCardPositionXY={setPersonaPositionXY}
                    />
                </div>

                <div className="sm:col-span-1 mt-4">
                    <button className='w-full justify-center rounded  bg-sky-500 px-3 py-1
                    text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={() => updateWebView()}>Update</button>
                </div>
            </div>
        </form>
    )
}

export default ConfigWebView
import { useState, useEffect, useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { JSONTree } from 'react-json-tree';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import BocceWorkflow from './BocceWorkflow';
import BocceWorkflowNews from './BocceWorkflowNews';
import { usePDF } from 'react-to-pdf';
import { ImpulseSpinner, RotateSpinner } from "react-spinners-kit";
import html2pdf from 'html2pdf.js';

// Define the SVG for three dots
const ThreeDotsSVG = () => (
    <svg width="28" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="12" r="3" fill="#333" />
        <circle cx="16" cy="12" r="3" fill="#333" />
        <circle cx="28" cy="12" r="3" fill="#333" />
    </svg>
);

// Add new Context Analysis section
const ContextAnalysisSection = ({ contextAnalysis, loading }) => (
    <div className="mt-4">
        <h2 className="text-lg font-semibold text-zinc-100 mb-3">Story Context Analysis</h2>
        {loading ? (
            <Skeleton height={100} />
        ) : contextAnalysis && (
            <div className="space-y-6">
                {/* Key Themes */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <h3 className="text-sky-400 font-medium mb-2">Key Themes</h3>
                    <ul className="list-disc pl-4 space-y-1">
                        {contextAnalysis.themes.map((theme, idx) => (
                            <li key={idx} className="text-zinc-400">{theme}</li>
                        ))}
                    </ul>
                </div>

                {/* Turning Points */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <h3 className="text-sky-400 font-medium mb-2">Critical Turning Points</h3>
                    <ul className="list-disc pl-4 space-y-1">
                        {contextAnalysis.turningPoints.map((point, idx) => (
                            <li key={idx} className="text-zinc-400">{point}</li>
                        ))}
                    </ul>
                </div>

                {/* Value Propositions */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <h3 className="text-sky-400 font-medium mb-2">Value Propositions</h3>
                    <div className="space-y-3">
                        <div>
                            <h4 className="text-zinc-300 font-medium">Business</h4>
                            <p className="text-zinc-400">{contextAnalysis.valueProps.business}</p>
                        </div>
                        <div>
                            <h4 className="text-zinc-300 font-medium">Technical</h4>
                            <p className="text-zinc-400">{contextAnalysis.valueProps.technical}</p>
                        </div>
                        <div>
                            <h4 className="text-zinc-300 font-medium">Customer</h4>
                            <p className="text-zinc-400">{contextAnalysis.valueProps.customer}</p>
                        </div>
                    </div>
                </div>

                {/* Technology Highlights */}
                <div className="bg-zinc-800/50 rounded p-4">
                    <h3 className="text-sky-400 font-medium mb-2">Technology Implementation Highlights</h3>
                    <ul className="list-disc pl-4 space-y-1">
                        {contextAnalysis.techHighlights.map((highlight, idx) => (
                            <li key={idx} className="text-zinc-400">{highlight}</li>
                        ))}
                    </ul>
                </div>
            </div>
        )}
    </div>
);

// Update the ProducedStorySection to include context analysis
const ProducedStorySection = ({ producedStory, loading }) => (
    <div className="space-y-8">
        {/* Context Analysis */}
        {producedStory?.contextAnalysis && (
            <ContextAnalysisSection
                contextAnalysis={producedStory.contextAnalysis}
                loading={loading}
            />
        )}

        {/* Title and Subtitle */}
        <div className="text-center py-6">
            <h1 className="text-2xl font-bold text-zinc-100 mb-2">
                {producedStory?.title?.title || <Skeleton width={300} />}
            </h1>
            <p className="text-lg text-sky-400">
                {producedStory?.title?.subtitle || <Skeleton width={200} />}
            </p>
        </div>

        {/* Opening */}
        <div className="bg-zinc-800/50 rounded p-6">
            <h2 className="text-lg font-semibold text-zinc-100 mb-3">Opening</h2>
            <div className="text-zinc-400">
                {producedStory?.opening || <Skeleton count={3} />}
            </div>
        </div>

        {/* Middle Scenes */}
        {producedStory?.middleScenes?.length > 0 && (
            <div className="space-y-6">
                {producedStory.middleScenes.map((scene, index) => (
                    <div key={index} className="bg-zinc-800/50 rounded p-6">
                        <div className="flex items-center gap-2 mb-3">
                            <span className="bg-sky-500/20 text-sky-400 px-2 py-1 rounded-full text-xs">
                                Scene {index + 1}
                            </span>
                            <h3 className="text-zinc-100 font-medium">{scene.title}</h3>
                        </div>
                        <div className="text-zinc-400">
                            {scene.content}
                        </div>
                    </div>
                ))}
            </div>
        )}

        {/* Closing */}
        <div className="bg-zinc-800/50 rounded p-6">
            <h2 className="text-lg font-semibold text-zinc-100 mb-3">Closing</h2>
            <div className="text-zinc-400">
                {producedStory?.closing || <Skeleton count={3} />}
            </div>
        </div>
    </div>
);

export default function CompAIStoryBord2() {
    const baseColor = '#1E1E25';
    const highlightColor = '#2C2C30';
    const [loading, setLoading] = useState(false);
    const [researchData, setResearchData] = useState({
        customerInsights: null,
        productInsights: null
    });
    const [storyData, setStoryData] = useState({
        introduction: null,
        scenes: { scenes: [] },
        conclusion: null,
        personas: []
    });
    const [error, setError] = useState(null);
    const [personaData, setPersonaData] = useState(null);
    const [salesAnalysisData, setSalesAnalysisData] = useState(null);
    const [formData, setFormData] = useState({
        // promptCompanyName: 'Salesforce',
        // promptProductName: 'Marketing Cloud',
        // promptCustomerName: 'Sony',
        // promptCustomerRole: 'SVP Marketing',
        // promptCustomerProblem: 'connect with the most likely customers for their movies based on the latest forms of communication'
        promptCompanyName: 'Procore',
        promptProductName: 'Project Management',
        promptCustomerName: 'Turner Construction',
        promptCustomerRole: 'Director',
        promptCustomerProblem: 'Enhancing Innovation and Technological Integration: The construction sector is rapidly evolving with new technologies. Turner addresses this by fostering innovation through dedicated teams and initiatives like the Turner Innovation Summit, which explores advancements such as artificial intelligence to improve construction processes'
    });
    const [producedStory, setProducedStory] = useState({
        contextAnalysis: null,
        title: null,
        opening: null,
        middleScenes: [],
        closing: null
    });
    const [storyVisualizationData, setStoryVisualizationData] = useState(null);
    const [storyChaptersData, setStoryChaptersData] = useState(null);
    const [apiCalls, setApiCalls] = useState({
        research: false,
        persona: false,
        story: false,
        producer: false,
        talkTrack: false,
        salesAnalysis: false,
        wardrobe: false,
        businessNews: false,
        industryTrends: false,
        storyChapters: false,
    });
    const [talkTrackData, setTalkTrackData] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [sectionTimes, setSectionTimes] = useState({
        research: null,
        persona: null,
        story: null,
        producer: null,
        talkTrack: null,
        salesAnalysis: null,
        wardrobe: null,
        businessNews: null,
        industryTrends: null
    });
    const [wardrobeData, setWardrobeData] = useState(null);
    const [businessNewsData, setBusinessNewsData] = useState(null);
    const [industryTrendsData, setIndustryTrendsData] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(false);

    const jsonTreeTheme = {
        scheme: 'monokai',
        base00: 'transparent',
        base01: '#383830',
        base02: '#49483e',
        base03: '#75715e',
        base04: '#a59f85',
        base05: '#f8f8f2',
        base06: '#f5f4f1',
        base07: '#f9f8f5',
        base08: '#f92672',
        base09: '#fd971f',
        base0A: '#f4bf75',
        base0B: '#a6e22e',
        base0C: '#a1efe4',
        base0D: '#66d9ef',
        base0E: '#ae81ff',
        base0F: '#cc6633'
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Add timer effect
    useEffect(() => {
        let intervalId;
        if (isTimerRunning) {
            intervalId = setInterval(() => {
                setTimer(prev => prev + 10); // Update every 10ms
            }, 10);
        }
        return () => clearInterval(intervalId);
    }, [isTimerRunning]);

    // Format time function
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        const milliseconds = Math.floor((time % 1000) / 10);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(2, '0')}`;
    };

    const playSound = () => {
        const audio = new Audio('/media/notifications/section-done-2.wav');
        audio.play();
    };



    const generateStory = async (e) => {
        e.preventDefault();
        setError(null);
        setTimer(0);
        setIsTimerRunning(true);
        setLoading(true);
        setShowProgressBar(true);

        // Declare producedResult here so it's accessible throughout the function
        let producedResult;

        try {
            // Research call
            setApiCalls(prev => ({ ...prev, research: true }));
            const researchStartTime = Date.now();
            const researchResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsResearcher?' + new URLSearchParams(formData));
            if (!researchResponse.ok) throw new Error(`HTTP error! status: ${researchResponse.status}`);
            const researchResult = await researchResponse.json();
            console.log('Research API Response:', researchResult);
            setResearchData(researchResult);
            setApiCalls(prev => ({ ...prev, research: false }));
            setSectionTimes(prev => ({ ...prev, research: Date.now() - researchStartTime }));
            playSound(); // Play sound after research call

            // Business News call
            setApiCalls(prev => ({ ...prev, businessNews: true }));
            const businessNewsStartTime = Date.now();
            const businessNewsResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsBusinessNews?' + new URLSearchParams(formData));
            if (!businessNewsResponse.ok) throw new Error(`HTTP error! status: ${businessNewsResponse.status}`);
            const businessNewsResult = await businessNewsResponse.json();
            console.log('Business News API Response:', businessNewsResult);
            setBusinessNewsData(businessNewsResult);
            setApiCalls(prev => ({ ...prev, businessNews: false }));
            setSectionTimes(prev => ({ ...prev, businessNews: Date.now() - businessNewsStartTime }));
            playSound(); // Play sound after business news call

            // Industry Trends call (new step)
            setApiCalls(prev => ({ ...prev, industryTrends: true }));
            const industryTrendsStartTime = Date.now();
            const industryTrendsResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsIndustryTrends?' + new URLSearchParams(formData));
            if (!industryTrendsResponse.ok) throw new Error(`HTTP error! status: ${industryTrendsResponse.status}`);
            const industryTrendsResult = await industryTrendsResponse.json();
            console.log('Industry Trends API Response:', industryTrendsResult);
            setIndustryTrendsData(industryTrendsResult); // businessNewsResult, industryTrendsResult
            setApiCalls(prev => ({ ...prev, industryTrends: false }));
            setSectionTimes(prev => ({ ...prev, industryTrends: Date.now() - industryTrendsStartTime }));
            playSound(); // Play sound after industry trends call

            // Casting call
            setApiCalls(prev => ({ ...prev, persona: true }));
            const personaStartTime = Date.now();
            const castingResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsCasting?' + new URLSearchParams({
                ...formData,
                podcastInsights: JSON.stringify(researchResult.podcastInsights)
            }));
            if (!castingResponse.ok) throw new Error(`HTTP error! status: ${castingResponse.status}`);
            const personaResult = await castingResponse.json();
            console.log('Persona API Response:', personaResult);
            setPersonaData(personaResult);
            setApiCalls(prev => ({ ...prev, persona: false }));
            setSectionTimes(prev => ({ ...prev, persona: Date.now() - personaStartTime }));
            playSound(); // Play sound after casting call

            // Wardrobe call (new step)
            setApiCalls(prev => ({ ...prev, wardrobe: true }));
            // const wardrobeStartTime = Date.now();
            // const wardrobeResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsWardrobe', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         personas: personaResult.personas
            //     }),
            // });
            // if (!wardrobeResponse.ok) throw new Error(`HTTP error! status: ${wardrobeResponse.status}`);
            // const wardrobeResult = await wardrobeResponse.json();
            // console.log('Wardrobe API Response:', wardrobeResult);
            // setWardrobeData(wardrobeResult);
            setApiCalls(prev => ({ ...prev, wardrobe: false }));
            // setSectionTimes(prev => ({ ...prev, wardrobe: Date.now() - wardrobeStartTime }));
            // playSound(); // Play sound after wardrobe call

            // Story call
            /*
            businessNewsResult, industryTrendsResult
            */
            setApiCalls(prev => ({ ...prev, story: true }));
            const storyStartTime = Date.now();
            try {
                const storyResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsStoryteller?' + new URLSearchParams({
                    ...researchResult,
                    ...businessNewsResult,
                    ...industryTrendsResult,
                    ...formData,
                    personas: JSON.stringify(personaResult),
                    promptInspiration: formData.promptCustomerProblem
                }));

                if (!storyResponse.ok) throw new Error(`HTTP error! status: ${storyResponse.status}`);
                const storyResult = await storyResponse.json();
                console.log('Story API Response:', storyResult);
                setStoryData(storyResult);
                setApiCalls(prev => ({ ...prev, story: false }));
                setSectionTimes(prev => ({ ...prev, story: Date.now() - storyStartTime }));
                playSound(); // Play sound after story call

                // Only proceed with Producer call if storyResult exists and has required data
                if (storyResult ) {
                    // Producer call
                    setApiCalls(prev => ({ ...prev, producer: true }));
                    const producerStartTime = Date.now();
                    const producerResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsStoryProducer', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            // Form data
                            promptCompanyName: formData.promptCompanyName,
                            promptProductName: formData.promptProductName,
                            promptCustomerName: formData.promptCustomerName,
                            promptCustomerRole: formData.promptCustomerRole,
                            promptCustomerProblem: formData.promptCustomerProblem,

                            // Story data
                            story: storyResult,
                            story_scenes: storyResult.scenes,
                            story_introduction: storyResult.introduction,
                            story_conclusion: storyResult.conclusion,
                            story_personas: personaResult.personas,
                            story_introduction_summary: storyResult.introduction,
                            story_conclusion_summary: storyResult.conclusion,
                            story_conclusion_benefits: storyResult.conclusion,

                            // Add required prompts
                            titlePrompt: `Create a compelling title for the story of how ${formData.promptCustomerName} solved ${formData.promptCustomerProblem} using ${formData.promptProductName}`,
                            openingPrompt: `Create an engaging opening for the story of how ${formData.promptCustomerName} implemented ${formData.promptProductName}`,
                            closingPrompt: `Create a powerful conclusion highlighting how ${formData.promptProductName} helped ${formData.promptCustomerName} solve ${formData.promptCustomerProblem}`,

                            // Additional form data if needed
                            ...formData
                        }),
                    });

                    if (!producerResponse.ok) throw new Error(`HTTP error! status: ${producerResponse.status}`);
                    producedResult = await producerResponse.json(); // Assign response to producedResult
                    console.log('Producer API Response:', producedResult);
                    setProducedStory(producedResult);
                    setStoryVisualizationData(producedResult);
                    setApiCalls(prev => ({ ...prev, producer: false }));
                    setSectionTimes(prev => ({ ...prev, producer: Date.now() - producerStartTime }));
                    playSound(); // Play sound after producer call
                } else {
                    throw new Error('Story data incomplete or missing required fields');
                }
            } catch (error) {
                console.error('Error in Story/Producer sequence:', error);
                setApiCalls(prev => ({ ...prev, story: false, producer: false }));
                throw error; // Re-throw to be caught by the outer try-catch
            }

            // Now producedResult is accessible here
            if (producedResult) {
                setApiCalls(prev => ({ ...prev, storyChapters: true }));
                const storyChaptersStartTime = Date.now();
                
                try {
                    const storyChaptersResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsStoryChapters', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        body: JSON.stringify({
                            producedWrapper: producedResult,
                        }),
                    });
    
                    if (!storyChaptersResponse.ok) {
                        throw new Error(`HTTP error! status: ${storyChaptersResponse.status}`);
                    }
    
                    const storyChaptersResult = await storyChaptersResponse.json();
                    console.log('Story Chapters API Response:', storyChaptersResult);
                    setStoryChaptersData(storyChaptersResult);
                    setApiCalls(prev => ({ ...prev, storyChapters: false }));
                    setSectionTimes(prev => ({ ...prev, storyChapters: Date.now() - storyChaptersStartTime }));
                    playSound(); // Play sound after story chapters call
                } catch (error) {
                    console.error('Story Chapters API Error:', error);
                    setError(`Story Chapters Error: ${error.message}`);
                    setApiCalls(prev => ({ ...prev, storyChapters: false }));
                }
            }


            // // Talk Track call (now using producedResult)
            // setApiCalls(prev => ({ ...prev, talkTrack: true }));
            // const talkTrackStartTime = Date.now();
            // const talkTrackResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsTalktrack', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         story: producedResult,
            //         ...formData
            //     }),
            // });
            // if (!talkTrackResponse.ok) throw new Error(`HTTP error! status: ${talkTrackResponse.status}`);
            // const talkTrackResult = await talkTrackResponse.json();
            // console.log('Talk Track API Response:', talkTrackResult);
            // setTalkTrackData(talkTrackResult);
            // setApiCalls(prev => ({ ...prev, talkTrack: false }));
            // setSectionTimes(prev => ({ ...prev, talkTrack: Date.now() - talkTrackStartTime }));
            // playSound(); // Play sound after talk track call

            // // Sales Analysis call (new)
            // setApiCalls(prev => ({ ...prev, salesAnalysis: true }));
            // const salesAnalysisStartTime = Date.now();
            // const salesAnalysisResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/jobsSalesAnalysis?' + new URLSearchParams({
            //     ...formData,
            //     podcastInsights: JSON.stringify(researchResult.podcastInsights)
            // }));
            // if (!salesAnalysisResponse.ok) throw new Error(`HTTP error! status: ${salesAnalysisResponse.status}`);
            // const salesAnalysisResult = await salesAnalysisResponse.json();
            // console.log('Sales Analysis API Response:', salesAnalysisResult);
            // setSalesAnalysisData(salesAnalysisResult);
            // setApiCalls(prev => ({ ...prev, salesAnalysis: false }));
            // setSectionTimes(prev => ({ ...prev, salesAnalysis: Date.now() - salesAnalysisStartTime }));
            // playSound(); // Play sound after sales analysis call

            // After all API calls are complete
            setIsTimerRunning(false);
        } catch (err) {
            console.error('API Error:', err);
            setError(err.message);
            setApiCalls({
                research: false,
                businessNews: false,
                industryTrends: false, // Add industry trends to error handling
                persona: false,
                wardrobe: false,
                story: false,
                producer: false,
                talkTrack: false,
                salesAnalysis: false,
                storyChapters: false
            });
            setIsTimerRunning(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('Wardrobe Data:', wardrobeData);
    }, [wardrobeData]);

    const getHeadingClass = (apiCall, data) => {
        if (apiCall) return "text-zinc-600";
        if (data) return "text-zinc-100";
        return "text-zinc-600";
    };

    const formatSectionTime = (ms) => {
        if (ms === null) return '';
        const seconds = Math.floor(ms / 1000);
        const milliseconds = ms % 1000;
        return `${seconds}.${milliseconds.toString().padStart(3, '0')}s`;
    };

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div className="' + className + ' w-8 h-8">' + (index + 1) + '</div>';
        },
    };

    const storyPathRef = useRef();
    const businessNewsRef = useRef();
    const producedStoryRef = useRef();
    const researchRef = useRef();
    const industryTrendsRef = useRef();
    const personasRef = useRef();
    const castRef = useRef();
    const draftStoryRef = useRef();
    const talkTrackRef = useRef();
    const salesAnalysisRef = useRef();


    const [transcriptResults, setTranscriptResults] = useState([]);
    const [transcriptData, setTranscriptData] = useState([]);

    // Initialize usePDF hook
    const { toPDF: generatePDF } = usePDF();

    const generateSectionPDF = (sectionRef, sectionName) => {
        if (sectionRef.current) {
            generatePDF({
                filename: `${formData.promptCompanyName}_${sectionName}.pdf`,
                targetRef: sectionRef.current
            });
        } else {
            console.error("Unable to get the target element.");
        }
    };

    const TranscriptAnalysis = ({ analysisData }) => {
        // Add null check before accessing data
        // if (!analysisData || !Array.isArray(analysisData)) {
        //     return null;
        // }

        // Collect all unique key topics across all analyses
        const allKeyTopics = [...new Set(
            analysisData.flatMap(analysis => analysis.keyTopics || [])
        )];

        return (
            <div className="mb-6 bg-zinc-800/50 rounded-lg p-6">
                <h3 className="text-zinc-100 text-xl mb-6">Video Analysis Summary</h3>

                {/* Common Themes Across Videos */}
                <div className="mb-8">
                    <h4 className="text-sky-400 text-lg mb-4">Key Common Themes</h4>
                    <div className="flex flex-wrap gap-2">
                        {allKeyTopics.map((theme, index) => (
                            <span key={index} className="bg-sky-500/10 text-sky-300 px-3 py-1 rounded-full text-sm">
                                {theme}
                            </span>
                        ))}
                    </div>
                </div>

                {/* Individual Video Analysis */}
                <div className="space-y-8">
                    {analysisData.map((analysis, index) => (
                        <div key={index} className="bg-black/20 rounded-lg p-6">
                            <h4 className="text-zinc-100 text-lg mb-4">
                                Analysis {index + 1}: {analysis.keyTopics?.[0] || 'Untitled'}
                            </h4>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Insights Section */}
                                <div>
                                    <h5 className="text-sky-400 mb-3">Key Insights</h5>
                                    <ul className="list-disc pl-4 space-y-2 text-zinc-300">
                                        {analysis.insights?.map((insight, i) => (
                                            <li key={i}>{insight}</li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Examples Section */}
                                {analysis.examples?.length > 0 && (
                                    <div>
                                        <h5 className="text-sky-400 mb-3">Examples</h5>
                                        <ul className="list-disc pl-4 space-y-2 text-zinc-300">
                                            {analysis.examples.map((example, i) => (
                                                <li key={i}>{example}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {/* Quotes Section */}
                                {analysis.quotes?.length > 0 && (
                                    <div className="col-span-full">
                                        <h5 className="text-sky-400 mb-3">Notable Quotes</h5>
                                        <div className="space-y-2 text-zinc-300">
                                            {analysis.quotes.map((quote, i) => (
                                                <p key={i} className="italic">"{quote.replace(/['"]/g, '')}"</p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    //const getTargetElement = () => document.getElementById('content-id');

    return (
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
            <div className="container mx-auto pr-8 -mr-4 -ml-4  
            overflow-y-auto 
            h-screen pb-24">
                <h1 className="text-2xl text-zinc-100 mb-4 ml-4 mx-auto text-center pb-4">Create Storypath</h1>

                <form onSubmit={generateStory} className="space-y-4  text-zinc-100  p-8 bg-zinc-900 rounded">

                    <div className='flex justify-between items-center '>
                        <div className='flex-grow'>
                            <div className="flex flex-wrap items-center gap-2 ">

                                <input
                                    type="text"
                                    name="promptCompanyName"
                                    value={formData.promptCompanyName}
                                    placeholder="Your Company Name"
                                    onChange={handleInputChange}
                                    className="inline-flex flex-grow px-3 py-2 bg-zinc-800 text-zinc-50 rounded border border-zinc-800 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                    required
                                />
                                <div className="px-4">would like to sell</div>
                                <input
                                    type="text"
                                    name="promptProductName"
                                    placeholder="Product Name"
                                    value={formData.promptProductName}
                                    onChange={handleInputChange}
                                    className="inline-flex flex-grow px-3 py-2 bg-zinc-800 text-zinc-50 rounded border border-zinc-800 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                    required
                                />
                                <div className="px-4">to the</div>
                                <input
                                    type="text"
                                    name="promptCustomerRole"
                                    placeholder="Target Role"
                                    value={formData.promptCustomerRole}
                                    onChange={handleInputChange}
                                    className="inline-flex flex-grow px-3 py-2 bg-zinc-800 text-zinc-50 rounded border border-zinc-800 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                    required
                                />
                                <div className="px-4">at</div>
                                <input
                                    type="text"
                                    name="promptCustomerName"
                                    placeholder="Prospect Company Name"
                                    value={formData.promptCustomerName}
                                    onChange={handleInputChange}
                                    className="inline-flex flex-grow px-3 py-2 bg-zinc-800 text-zinc-50 rounded border border-zinc-800 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                    required
                                />

                            </div>
                            <div className='flex mt-4'>
                                <div className="px-4 mt-2">in order to overcome</div>
                                <input
                                    name="promptCustomerProblem"
                                    value={formData.promptCustomerProblem}
                                    onChange={handleInputChange}
                                    className="inline-flex flex-grow px-3 py-2 bg-zinc-800 text-zinc-50 rounded border border-zinc-800 focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                                    rows="3"
                                    required
                                />
                            </div>
                        </div>

                        <div className="pl-12 pr-8 ">
                            <div
                                className="relative p-0.5 inline-flex items-center justify-center cursor-pointer overflow-hidden group rounded-full  
                                
                                "
                                onClick={(e) => {
                                    e.preventDefault();
                                    generateStory(e);
                                }}
                            >
                                <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] 
                                
                                group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                                <span className="w-full text-center relative px-4 py-3 transition-all ease-out bg-zinc-900 rounded-full group-hover:bg-opacity-0 duration-400">
                                    <span className="relative text-white text-base">
                                        {loading ?
                                            <RotateSpinner color="#ff5478" className="mx-auto" size="20" /> :
                                            <img src="/util/app/b3-admin-play.svg" className="h-4   inline" />
                                        }
                                    </span>
                                </span>
                            </div>
                        </div>

                    </div>




                    <div className=" w-full">
                        <div className=" items-center ">

                            {!showProgressBar && (
                                <div className='mx-auto'>
                                    {(timer > 0 || isTimerRunning) && (
                                        <div className="text-zinc-100 font-mono mx-auto w-full text-center mt-4 text-lg">
                                            {formatTime(timer)}
                                        </div>
                                    )}
                                </div>
                            )}

                            {showProgressBar && (
                                <div className=" animate__animated animate__fadeIn">
                                    <div className="relative">
                                        <div className="grid grid-cols-8 gap-1 bg-zinc-900 rounded   rounded-md">
                                            {[
                                                { name: 'Research', key: 'research' },
                                                { name: 'Business News', key: 'businessNews' },
                                                { name: 'Industry Trends', key: 'industryTrends' },
                                                { name: 'Personas', key: 'persona' },
                                                // { name: 'Cast', key: 'wardrobe' },
                                                { name: 'Draft Story', key: 'story' },
                                                { name: 'Produced Story', key: 'producer' },
                                                { name: 'Talk Track', key: 'talkTrack' },
                                                { name: 'Sales Analysis', key: 'salesAnalysis' }
                                            ].map((step) => (
                                                <div key={step.name} className="flex flex-col items-center">
                                                    <div className={`relative w-full rounded h-1 -mb-2 mt-4  ${apiCalls[step.key] ? 'bg-sky-400 pulse-sky' :
                                                        sectionTimes[step.key] ? 'bg-sky-300' : 'bg-white/5'
                                                        }`}>
                                                    </div>
                                                    <div className="text-center">
                                                        <h3 className={`font-medium mt-4 text-sm ${apiCalls[step.key] ? 'text-zinc-100' :
                                                            sectionTimes[step.key] ? 'text-zinc-100' :
                                                                'text-zinc-600'
                                                            }`}>
                                                            {step.name}
                                                        </h3>
                                                        {sectionTimes[step.key] && (
                                                            <span className="text-xs text-zinc-400">
                                                                {formatSectionTime(sectionTimes[step.key])}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {/* <div className="flex justify-end">
                                                <button
                                                    onClick={() => generatePDF(storyPathRef, 'StoryPath')}
                                                    className="bg-zinc-800 hover:bg-zinc-600 text-white py-2 px-4 rounded flex items-center gap-2"
                                                >
                                                    <img src="/util/app/b3-admin-download-pdf.svg" className="w-4 h-4" />  PDF
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* <div className="flex justify-end">
                            <button
                                onClick={() => toPDF()}
                                className="bg-zinc-800 hover:bg-zinc-600 text-white py-2 px-4 rounded flex items-center gap-2"
                            >
                                <img src="/util/app/b3-admin-icon-pdf.svg" className="w-4 h-4" /> Download PDF
                            </button>
                        </div> */}
                        </div>
                    </div>
                </form>



                {/* <div className='flex justify-between items-center -mt-6'>
                </div> */}


                {/* bg-gradient-to-b from-[#ff8a05] via-[#ff5478] to-[#ff00c6]
                animate-gradient bg-[length:100%_100%] */}

                {(apiCalls.research == 3333 ?
                    <div className='flex justify-between items-center w-full'>
                        <div className='w-1 
                        bg-gradient-to-b from-[#ff8a05] via-[#ff5478] to-[#ff00c6]
                animate-gradient bg-[length:100%_100%] 
                        h-12 mx-auto'></div>
                    </div>
                    :
                    <div className='flex justify-between items-center w-full'>
                        <div className='w-1 bg-black/0 h-12 mx-auto'></div>
                    </div>
                )}

                {/* <BocceWorkflow />
                <BocceWorkflowNews /> */}

                {error && (
                    <div className="mt-4 p-4 bg-black/50 text-red-100 rounded">
                        {error}
                    </div>
                )}





                {/* <div className="mb-4 flex justify-end">
                        <button
                            onClick={async () => {
                                try {
                                    const response = await fetch('http://localhost:5001/brevit3-57b00/us-central1/runTranscript');
                                    if (!response.ok) throw new Error('Network response was not ok');
                                    const data = await response.json();

                                    // Create a formatted display of results
                                    const formattedResults = data.video_results?.map(video => ({
                                        title: video.title,
                                        link: video.link,
                                        thumbnail: video.thumbnail?.static,
                                        duration: video.duration,
                                        views: video.views,
                                        published_date: video.published_date
                                    })) || [];

                                    // Update state with results
                                    setTranscriptResults(formattedResults);
                                    console.log('YouTube Results:', formattedResults);



                                } catch (error) {
                                    console.error('Error fetching transcript:', error);
                                    setError('Failed to fetch YouTube results');
                                }
                                finally {
                                    //console.log('YouTube Results:', formattedResults);
                                }
                            }}
                            className="px-4 py-2 bg-zinc-800 text-zinc-50 rounded hover:bg-zinc-700 transition-colors"
                        >
                            Get YouTube Results
                        </button>
                    </div> */}





                <button
                    onClick={async () => {
                        try {
                            // First get YouTube results
                            const response = await fetch('http://localhost:5001/brevit3-57b00/us-central1/runTranscript', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    promptCompanyName: formData.promptCompanyName,
                                    promptProductName: formData.promptProductName
                                }),
                            });

                            if (!response.ok) throw new Error('Network response was not ok');
                            const data = await response.json();

                            // Format the results
                            const formattedResults = data.video_results?.map(video => ({
                                title: video.title,
                                link: video.link,
                                thumbnail: video.thumbnail?.static,
                                duration: video.duration,
                                views: video.views,
                                published_date: video.published_date
                            })) || [];

                            // Update state with results
                            setTranscriptResults(formattedResults);

                            // Get transcripts for top 5 videos
                            const top5Links = formattedResults.slice(0, 5).map(result => result.link);
                            const transcriptResponse = await fetch('http://localhost:5001/brevit3-57b00/us-central1/runYouTubeTranscript', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    videoLinks: top5Links,
                                    promptCompanyName: formData.promptCompanyName,
                                    promptProductName: formData.promptProductName,
                                    promptCustomerName: formData.promptCustomerName,
                                    promptCustomerRole: formData.promptCustomerRole,
                                    promptCustomerProblem: formData.promptCustomerProblem,
                                    result: data.video_results
                                }),
                            });

                            if (!transcriptResponse.ok) throw new Error('Failed to fetch transcripts');
                            const transcriptData = await transcriptResponse.json();
                            setTranscriptData(transcriptData);

                            console.log('Transcript Data:', transcriptData);

                        } catch (error) {
                            console.error('Error:', error);
                            setError('Failed to fetch results');
                        }
                    }}
                    className="px-4 py-2 bg-zinc-800 text-zinc-50 rounded hover:bg-zinc-700 transition-colors"
                >
                    Get YouTube Results
                </button>








                {transcriptData && transcriptData.individualAnalyses && (
                    <div className="mb-6 bg-zinc-800/50 rounded-lg p-6">
                        <h3 className="text-zinc-100 text-xl mb-6">Video Analysis Summary</h3>

                        <div className='p-8 bg-black/20 rounded-lg mb-8'>
                            {/* Common Themes Section */}
                            {/* <div className="mb-8">
                                <h4 className="text-sky-400 text-lg mb-4">Key Common Themes</h4>
                                <div className="flex flex-wrap gap-2">
                                    {transcriptData.summary?.commonThemes.map((theme, index) => (
                                        <span key={index} className="bg-sky-500/10 text-sky-300 px-3 py-1 rounded-full text-sm">
                                            {theme}
                                        </span>
                                    ))}
                                </div>
                            </div> */}

                            {/* Key Differences Section
                            <div className="mb-8">
                                <h4 className="text-sky-400 text-lg mb-4">Key Differences</h4>
                                <ul className="list-disc pl-6 space-y-2">
                                    {transcriptData.summary?.keyDifferences.map((diff, index) => (
                                        <li key={index} className="text-zinc-300">{diff}</li>
                                    ))}
                                </ul>
                            </div> */}

                            {/* Significant Insights Section */}
                            <div className="mb-8">
                                <h4 className="text-sky-400 text-lg mb-4">Significant Insights</h4>
                                <ul className="list-disc pl-6 space-y-2">
                                    {transcriptData.summary?.significantInsights.map((insight, index) => (
                                        <li key={index} className="text-zinc-300">{insight}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* Individual Analyses */}
                        <div className="space-y-8">
                            <h4 className="text-sky-400 text-lg mb-4">Individual Video Analyses</h4>
                            {transcriptData.individualAnalyses?.map((analysis, index) => (
                                <div key={index} className="bg-black/20 rounded-lg p-6">
                                    <div className="flex flex-wrap">
                                        {/* Left Column */}
                                        <div className="w-full lg:w-1/2 lg:pr-4">
                                            {/* Key Topics */}
                                            <div className="mb-6">
                                                <img src={`${transcriptData.result[index].thumbnail.static}`} className="w-full aspect-video rounded-lg mb-2" />

                                                <h5 className="text-zinc-100 font-medium mb-3">Key Topics</h5>
                                                <div className="flex flex-wrap gap-2">
                                                    {analysis.keyTopics?.map((topic, i) => (
                                                        <span key={i} className="bg-sky-500/10 text-sky-300 px-2 py-1 rounded-full text-xs">
                                                            {topic}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>


                                        </div>

                                        {/* Right Column */}
                                        <div className="w-full lg:w-1/2 lg:pl-4">
                                            {/* Insights */}
                                            <div className="mb-6 bg-black/20 rounded-lg p-4">
                                                <h5 className="text-zinc-100 font-medium mb-3">Key Insights</h5>
                                                <ul className="list-disc pl-4 space-y-2">
                                                    {analysis.insights?.map((insight, i) => (
                                                        <li key={i} className="text-zinc-300 text-sm">{insight}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {/* Examples */}
                                            {analysis.examples?.length > 0 && (
                                                <div className="mb-6 bg-black/20 rounded-lg p-4">
                                                    <h5 className="text-zinc-100 font-medium mb-3">Examples</h5>
                                                    <ul className="list-disc pl-4 space-y-2">
                                                        {analysis.examples.map((example, i) => (
                                                            <li key={i} className="text-zinc-300 text-sm">{example}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {/* Quotes */}
                                            {analysis.quotes?.length > 0 && (
                                                <div className="mb-6 bg-black/20 rounded-lg p-4">
                                                    <h5 className="text-zinc-100 font-medium mb-3">Notable Quotes</h5>
                                                    <ul className="space-y-2">
                                                        {analysis.quotes.map((quote, i) => (
                                                            <li key={i} className="text-zinc-300 text-sm italic">"{quote}"</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}








                {/* {transcriptData   && (
                    <TranscriptAnalysis analysisData={transcriptData.map(item => item.individualAnalysis)} />
                )} */}



                {transcriptData && transcriptData.length > 0 && (
                    <div className="mb-6 bg-zinc-800/50 rounded-lg p-4">
                        <h3 className="text-zinc-100 text-lg mb-4">Video Analysis</h3>

                        {/* Summary Section */}
                        {transcriptData.summary && (
                            <div className="mb-8 bg-black/20 rounded-lg p-6">
                                <h4 className="text-zinc-100 font-medium mb-4 text-lg">Overall Summary</h4>
                                <div className="space-y-4">
                                    <div>
                                        <h5 className="text-sky-400 text-sm mb-2">Common Themes</h5>
                                        <ul className="list-disc pl-4 text-zinc-300">
                                            {transcriptData.summary.commonThemes.map((theme, i) => (
                                                <li key={i}>{theme}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className="text-sky-400 text-sm mb-2">Key Differences</h5>
                                        <ul className="list-disc pl-4 text-zinc-300">
                                            {transcriptData.summary.keyDifferences.map((diff, i) => (
                                                <li key={i}>{diff}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className="text-sky-400 text-sm mb-2">Key Insights</h5>
                                        <ul className="list-disc pl-4 text-zinc-300">
                                            {transcriptData.summary.significantInsights.map((insight, i) => (
                                                <li key={i}>{insight}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Individual Video Analyses */}
                        <div className="space-y-6">
                            {transcriptData.map((item, index) => (
                                <div key={index} className="bg-zinc-900 rounded-lg p-6">
                                    <div className="flex justify-between items-start mb-4">
                                        <h4 className="text-zinc-100 font-medium">Video {index + 1}</h4>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-sky-400 hover:text-sky-300 text-sm"
                                        >
                                            View Video →
                                        </a>
                                    </div>

                                    {item.error ? (
                                        <p className="text-red-400">{item.error}</p>
                                    ) : (
                                        <div className="space-y-6">





                                            {/* Analysis Section */}
                                            {item.analysis && (
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    <div className="bg-black/20 rounded-lg p-4">
                                                        <h5 className="text-sky-400 text-sm mb-3">Key Topics</h5>
                                                        <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                            {item.analysis.keyTopics.map((topic, i) => (
                                                                <li key={i}>{topic}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="bg-black/20 rounded-lg p-4">
                                                        <h5 className="text-sky-400 text-sm mb-3">Main Insights</h5>
                                                        <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                            {item.analysis.mainInsights.map((insight, i) => (
                                                                <li key={i}>{insight}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    {item.analysis.examples.length > 0 && (
                                                        <div className="bg-black/20 rounded-lg p-4">
                                                            <h5 className="text-sky-400 text-sm mb-3">Examples & Case Studies</h5>
                                                            <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                                {item.analysis.examples.map((example, i) => (
                                                                    <li key={i}>{example}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    {item.analysis.quotes.length > 0 && (
                                                        <div className="bg-black/20 rounded-lg p-4">
                                                            <h5 className="text-sky-400 text-sm mb-3">Notable Quotes</h5>
                                                            <ul className="list-disc pl-4 text-zinc-300 space-y-1">
                                                                {item.analysis.quotes.map((quote, i) => (
                                                                    <li key={i}>{quote}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {/* Transcript Section */}
                                            {/* <div>
                                                <button
                                                    onClick={() => {
                                                        const transcriptEl = document.getElementById(`transcript-${index}`);
                                                        transcriptEl.classList.toggle('max-h-60');
                                                        transcriptEl.classList.toggle('max-h-full');
                                                    }}
                                                    className="text-sky-400 hover:text-sky-300 text-sm mb-2"
                                                >
                                                    Toggle Full Transcript
                                                </button>
                                                <div
                                                    id={`transcript-${index}`}
                                                    className="max-h-60 overflow-y-auto text-zinc-300 bg-black/20 rounded-lg p-4 transition-all duration-300"
                                                >
                                                    {item.transcript.map((line, i) => (
                                                        <p key={i} className="mb-1">
                                                            [{formatTime(line.offset)}] {line.text}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div> */}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}



                {/* Display Results */}
                {/* {transcriptResults && transcriptResults.length > 0 && (
                    <div className="mb-6 bg-zinc-800/50 rounded-lg p-4">
                        <h3 className="text-zinc-100 text-lg mb-4">YouTube Results</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {transcriptResults.map((result, index) => (
                                <div key={index} className="bg-zinc-900 rounded-lg p-4">
                                    {result.thumbnail && (
                                        <img
                                            src={result.thumbnail}
                                            alt={result.title}
                                            className="w-full h-40 object-cover rounded-lg mb-2"
                                        />
                                    )}
                                    <h4 className="text-zinc-100 font-medium mb-2">{result.title}</h4>
                                    <div className="text-zinc-400 text-sm">
                                        <p>Duration: {result.duration}</p>
                                        <p>Views: {result.views}</p>
                                        <p>Published: {result.published_date}</p>
                                    </div>
                                    <a
                                        href={result.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-sky-400 hover:text-sky-300 text-sm mt-2 block"
                                    >
                                        Watch Video →
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )} */}







                <div ref={storyPathRef} className=' ' >

                    <div className='rounded-md bg-black/20 p-4'>
                        <div className="mt-2" ref={researchRef} id="content-id">
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.research, researchData?.customerInsights || researchData?.productInsights)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.research ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Company Research</div>
                                    </div>
                                </h2>
                                {/* {(researchData?.customerInsights || researchData?.productInsights) && (
                                <button
                                    onClick={() => generateSectionPDF(researchRef, 'Research')}
                                    className="bg-zinc-800 hover:bg-zinc-600 text-white py-1 px-3 rounded text-sm"
                                >
                                    Download PDF
                                </button>
                            )} */}

                            </div>
                            {(apiCalls.research || (researchData?.customerInsights || researchData?.productInsights)) && (
                                <div className="  rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                    {apiCalls.research ? (
                                        <Skeleton height={128} />
                                    ) : researchData && (
                                        <div className="space-y-8 px-10 py-6">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {/* Customer Insights */}
                                                {researchData.customerInsights && (
                                                    <div className="">
                                                        <h3 className="text-lg font-semibold text-zinc-100 mb-3 border-l-4 border-sky-600 pl-4">Customer Insights</h3>
                                                        <div className="space-y-4">
                                                            <p className="text-zinc-400">{researchData.customerInsights.companyOverview}</p>
                                                            <div>
                                                                <h4 className="text-sky-400 font-medium mb-2">Business Goals</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {researchData.customerInsights.businessGoals?.map((goal, i) => (
                                                                        <li key={i} className="text-zinc-400 marker:text-sky-400">{goal}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Product Insights */}
                                                {researchData.productInsights && (
                                                    <div className=" ">
                                                        <h3 className="text-lg font-semibold text-zinc-100 mb-3 border-l-4 border-sky-600 pl-4">Product Insights</h3>
                                                        <div className="space-y-4">
                                                            <p className="text-zinc-400">{researchData.productInsights.productOverview}</p>
                                                            <div>
                                                                <h4 className="text-sky-400 font-medium mb-2">Key Features</h4>
                                                                <ul className="list-disc pl-4 space-y-2">
                                                                    {researchData.productInsights.keyFeatures?.map((feature, i) => (
                                                                        <li key={i} className="text-zinc-400 marker:text-sky-400">{feature}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>


                        {/* Display Business News Results */}
                        <div className="mt-4" ref={businessNewsRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.businessNews, businessNewsData?.newsItems)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.businessNews ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Business News Insights</div>
                                    </div>
                                </h2>
                                {/* {businessNewsData?.newsItems && (
                                <button
                                    onClick={() => generateSectionPDF(businessNewsRef, 'Business_News_Insights')}
                                    className="bg-zinc-800 hover:bg-zinc-600 text-white py-1 px-3 rounded text-sm"
                                >
                                    Download PDF
                                </button>
                            )} */}
                            </div>
                            {(apiCalls.businessNews || businessNewsData?.newsItems) && (
                                <div className="rounded text-sm px-10 py-6 text-zinc-300 h-content overflow-y-auto  ">
                                    {apiCalls.businessNews ? (
                                        <div className="grid grid-cols-1 gap-4">
                                            <Skeleton height={128} />
                                        </div>
                                    ) : businessNewsData && (
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {businessNewsData.newsItems.map((newsItem, index) => (
                                                <div key={index} className="">
                                                    <h3 className="text-lg font-semibold text-zinc-100 mb-3 border-l-4 border-sky-500 pl-4">{newsItem.headline}</h3>

                                                    <div className='mt-4'>
                                                        <h4 className="text-sky-400 font-medium mb-2">Sales Takeaways</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {newsItem.sales_takeaways?.map((takeaway, i) => (
                                                                <li key={i} className="text-zinc-400 marker:text-sky-400">{takeaway}</li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div>
                                                        <h4 className="text-sky-400 font-medium mb-2 mt-4">Summary</h4>
                                                        <p className="text-zinc-400 mb-2">{newsItem.summary}</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sky-400 font-medium mb-2 mt-4">Impact</h4>
                                                        <p className="text-zinc-400 mb-2">{newsItem.impact}</p>
                                                    </div>

                                                    <div className="text-zinc-400 text-sm">
                                                        <div className="text-zinc-400 text-xs flex gap-4">
                                                            <p><strong>Source:</strong> {newsItem.source}</p>
                                                            <p><strong>Relevance:</strong> {newsItem.relevance}</p>
                                                            <p><strong>Date Published:</strong> {newsItem.datePublished}</p>
                                                            <p>
                                                                <a href={newsItem.url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-sky-400 underline truncate block max-w-xs hover:underline"
                                                                    title={newsItem.url}>
                                                                    {newsItem.url.length > 40 ? newsItem.url.substring(0, 40) + '...' : newsItem.url}
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Display Industry Trends Results */}
                        <div className="mt-4" ref={industryTrendsRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.industryTrends, industryTrendsData?.trendItems)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.industryTrends ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Industry Trends Insights</div>
                                    </div>
                                </h2>
                                {/* {industryTrendsData?.trendItems && (
                                <button
                                    onClick={() => generateSectionPDF(industryTrendsRef, 'Industry_Trends_Insights')}
                                    className="bg-zinc-800 hover:bg-zinc-600 text-white py-1 px-3 rounded text-sm"
                                >
                                    Download PDF
                                </button>
                            )} */}
                            </div>
                            {(apiCalls.industryTrends || industryTrendsData?.trendItems) && (
                                <div className="rounded text-sm  px-10 py-6 text-zinc-300 h-content overflow-y-auto transition-opacity duration-300 ease-in-out opacity-100">
                                    {apiCalls.industryTrends ? (
                                        <div className="grid grid-cols-1 gap-4">
                                            <Skeleton height={128} />
                                        </div>
                                    ) : industryTrendsData && (
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {industryTrendsData.trendItems.map((trendItem, index) => (
                                                <div key={index} className="">
                                                    <h3 className="text-lg border-l-4 border-sky-500 pl-4 font-semibold text-zinc-100 mb-3">{trendItem.headline}</h3>

                                                    <div className='mt-4'>
                                                        <h4 className="text-sky-400 font-medium mb-2">Sales Takeaways</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {trendItem.sales_takeaways?.map((takeaway, i) => (
                                                                // ... existing code ...
                                                                <li key={i} className="text-zinc-400 marker:text-sky-400">{takeaway}</li>
                                                                // ... existing code ...
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div>
                                                        <h4 className="text-sky-400 font-medium mb-2 mt-4">Summary</h4>
                                                        <p className="text-zinc-400 mb-2">{trendItem.summary}</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sky-400 font-medium mb-2 mt-4">Impact</h4>
                                                        <p className="text-zinc-400 mb-2">{trendItem.impact}</p>
                                                    </div>

                                                    <div className="text-zinc-400 text-sm">
                                                        <p><strong>Source:</strong> {trendItem.source}</p>
                                                        <p><strong>Relevance:</strong> {trendItem.relevance}</p>
                                                        <p><strong>Date Published:</strong> {trendItem.datePublished}</p>
                                                        <p>
                                                            <a href={trendItem.url} target="_blank" rel="noopener noreferrer" className="text-sky-400 underline">
                                                                {trendItem.url}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='flex justify-between items-center w-full'>
                        <div className='w-1 bg-black/0 h-12 mx-auto'></div>
                    </div>


                    <div className='rounded-md  bg-black/20 p-4'>
                        <div className="mt-2" ref={personasRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.persona, personaData?.personas)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.persona ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Personas  <span className='text-sky-400 text-sm'>(TODO - what would be a win? + photo)</span></div>
                                    </div>
                                </h2>
                                {/* {personaData?.personas && (
                                <button
                                    onClick={() => generateSectionPDF(personasRef, 'Personas')}
                                    className="bg-zinc-800 hover:bg-zinc-600 text-white py-1 px-3 rounded text-sm"
                                >
                                    Download PDF
                                </button>
                            )} */}
                            </div>
                            {(apiCalls.persona || personaData?.personas) && (
                                <div className=" rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                    {apiCalls.persona ? (
                                        <Skeleton height={128} />
                                    ) : personaData?.personas && Array.isArray(personaData.personas) && (
                                        <div className="space-y-8 px-10 py-6">
                                            {personaData.personas.map((persona, index) => (
                                                <div key={index} className="   ">
                                                    <div className="flex items-start gap-6 mb-6">
                                                        {/* Persona Header Info */}
                                                        <div className="flex-grow">
                                                            <div className="flex items-center gap-3 mb-2">
                                                                <h4 className="text-zinc-100 font-medium">{persona.name}</h4>
                                                                <span className="bg-sky-500/20 text-sky-400 px-2 py-1 rounded-full text-xs font-medium">
                                                                    {persona.role}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                                                        {/* Goals Section */}
                                                        <div className="bg-black/20 rounded p-3">
                                                            <h5 className="text-zinc-300 font-medium mb-2">Goals</h5>
                                                            <ul className="list-disc pl-4 space-y-1">
                                                                {persona.goals.map((goal, idx) => (
                                                                    <li key={idx} className="text-zinc-400">{goal}</li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                        {/* Pain Points Section */}
                                                        <div className="bg-black/20 rounded p-3">
                                                            <h5 className="text-zinc-300 font-medium mb-2">Pain Points</h5>
                                                            <ul className="list-disc pl-4 space-y-1">
                                                                {persona.painPoints.map((point, idx) => (
                                                                    <li key={idx} className="text-zinc-400">{point}</li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                        {/* Success Metrics Section */}
                                                        <div className="bg-black/20 rounded p-3">
                                                            <h5 className="text-zinc-300 font-medium mb-2">Success Metrics</h5>
                                                            <ul className="list-disc pl-4 space-y-1">
                                                                {persona.successMetrics.map((metric, idx) => (
                                                                    <li key={idx} className="text-zinc-400">{metric}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    {/* Benefits Section */}
                                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                                        {persona.benefits.efficiency && (
                                                            <div className="bg-black/20 rounded p-3">
                                                                <h5 className="text-zinc-300 font-medium mb-2">Efficiency Benefits</h5>
                                                                <ul className="list-disc pl-4 space-y-1">
                                                                    {persona.benefits.efficiency.map((benefit, idx) => (
                                                                        <li key={idx} className="text-zinc-400 text-sm">{benefit}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {persona.benefits.satisfaction && (
                                                            <div className="bg-black/20 rounded p-3">
                                                                <h5 className="text-zinc-300 font-medium mb-2">Satisfaction Benefits</h5>
                                                                <ul className="list-disc pl-4 space-y-1">
                                                                    {persona.benefits.satisfaction.map((benefit, idx) => (
                                                                        <li key={idx} className="text-zinc-400 text-sm">{benefit}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {persona.benefits.business && (
                                                            <div className="bg-black/20 rounded p-3">
                                                                <h5 className="text-zinc-300 font-medium mb-2">Business Benefits</h5>
                                                                <ul className="list-disc pl-4 space-y-1">
                                                                    {persona.benefits.business.map((benefit, idx) => (
                                                                        <li key={idx} className="text-zinc-400 text-sm">{benefit}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* <div className="mt-4" ref={castRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.wardrobe, (wardrobeData && wardrobeData.length > 0))}`}>
                                    <div className="flex items-center">
                                        {apiCalls.wardrobe ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Cast</div>
                                    </div>
                                </h2>
                            </div>

                            {(apiCalls.wardrobe || (wardrobeData && wardrobeData.length > 0)) && (
                                <div className=" rounded  text-sm text-zinc-300 h-content overflow-y-auto">
                                    {apiCalls.wardrobe ? (
                                        <Skeleton height={0} />
                                    ) : (
                                        <div className="space-y-8 px-10 py-6">
                                            {wardrobeData.map((data, index) => (
                                                data.personas.map((persona, personaIndex) => (
                                                    <div key={`${index}-${personaIndex}`} className="border-l-4 border-sky-500 pl-4">
                                                        <h3 className="text-lg font-semibold text-zinc-100 mb-3">{persona.name}</h3>
                                                        <p className="text-zinc-400 mb-2">Role: {persona.role}</p>

                                                        
                                                        <div className="bg-zinc-800/50 rounded p-3 mb-3">
                                                            <h4 className="text-zinc-300 font-medium mb-2">Goals</h4>
                                                            <ul className="list-disc pl-4 space-y-1">
                                                                {persona.goals.map((goal, idx) => (
                                                                    <li key={idx} className="text-zinc-400">{goal}</li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                        
                                                        <div className="bg-zinc-800/50 rounded p-3 mb-3">
                                                            <h4 className="text-zinc-300 font-medium mb-2">Success Metrics</h4>
                                                            <ul className="list-disc pl-4 space-y-1">
                                                                {persona.successMetrics.map((metric, idx) => (
                                                                    <li key={idx} className="text-zinc-400">{metric}</li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                        
                                                        {persona.benefits && (
                                                            <div className="bg-zinc-800/50 rounded p-3">
                                                                <h4 className="text-zinc-300 font-medium mb-2">Benefits</h4>
                                                                <ul className="list-disc pl-4 space-y-1">
                                                                    {Object.entries(persona.benefits).map(([key, benefitsArray], idx) => (
                                                                        <li key={idx} className="text-zinc-400">
                                                                            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
                                                                            <ul className="list-disc pl-4 space-y-1">
                                                                                {benefitsArray.map((benefit, bIdx) => (
                                                                                    <li key={bIdx} className="text-zinc-400">{benefit}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div> */}
                    </div>



                    <div className='flex justify-between items-center w-full'>
                        <div className='w-1 bg-black/0 h-12 mx-auto'></div>
                    </div>


                    <div className='rounded-md  bg-black/20 p-4 '>
                        <div className="mt-2" ref={draftStoryRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.story, storyData?.story)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.story ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Draft Story</div>
                                    </div>
                                </h2>
                            </div>



                            {storyData?.story && (
                                <div className="text-zinc-400 ml-14 pl-1 pb-4 pt-2 ">
                                    Complete - Sending to Production Crew
                                </div>
                                // <div className="story-container p-6   rounded-lg ">


                                //     <div className="introduction mb-6">

                                //         <div className="text-zinc-300 mb-4 p-4">
                                //             <h3 className="text-xl font-semibold mb-2 text-zinc-100">Introduction</h3>
                                //             <p>
                                //                 {storyData.story.introduction.summary}
                                //             </p>
                                //         </div>
                                //         <div className="grid  grid-cols-3 gap-4">
                                //             {storyData.story.introduction.people.map((person, index) => (
                                //                 <div key={index} className="bg-black/20 rounded-lg p-4">
                                //                     <h4 className="text-zinc-100 font-medium text-lg">{person.name}</h4>
                                //                     <p className="text-sky-400 text-sm">{person.role}</p>
                                //                     <p className="text-zinc-400 text-sm mt-4">{person.goal}</p>
                                //                 </div>
                                //             ))}
                                //         </div>

                                //     </div>

                                //     <div className="scenes mt-12">
                                //         <h3 className="text-xl font-semibold mb-2 text-zinc-100 px-4">Scenes</h3>
                                //         {storyData.story.scenes.scenes.map((scene, index) => (
                                //             <div key={index} className="scene mb-4 mt-4  text-sm">
                                //                 <div className=' flex'>
                                //                     <div className="flex-grow    p-4 rounded-lg">
                                //                         <h4 className="text-lg font-semibold text-zinc-100">{scene.title}</h4>
                                //                         <p className="text-sky-400 text-sm mb-4">{scene.location.name} - {scene.location.type}</p>
                                //                         <p className="text-zinc-300 mb-2">{scene.description}</p>
                                //                         <p className="text-zinc-300 mb-2 mt-4">{scene.story}</p>

                                //                     </div>
                                //                     <div className="flex-none w-1/4 ml-8">
                                //                         <div className='rounded   p-4'>
                                //                             <p className='text-zinc-50 text-base mt-14 font-semibold mb-2'>Value</p>
                                //                             <p className="text-zinc-300 mb-2">{scene.customer_value} {scene.business_value}</p>
                                //                         </div>
                                //                     </div>
                                //                 </div>
                                //                 <div className="text-zinc-300 p-4">
                                //                     <img src="/util/app/b3-admin-talk-track.svg" className='h-8 inline mr-4' /> {scene.talk_track}
                                //                 </div>
                                //             </div>

                                //         ))}
                                //     </div>

                                //     <div className="conclusion">
                                //         <h3 className="text-xl font-semibold mb-2 text-zinc-100">Conclusion</h3>
                                //         <p className="text-zinc-300 mb-4">{storyData.story.conclusion.summary}</p>
                                //         <ul className="list-disc pl-5">
                                //             {storyData.story.conclusion.benefits.map((benefit, index) => (
                                //                 <li key={index} className="text-zinc-400">
                                //                     <strong>{benefit.name}</strong> - {benefit.role}
                                //                     <ul className="list-disc pl-5">
                                //                         {benefit.benefits.map((b, idx) => (
                                //                             <li key={idx} className="text-zinc-400">{b}</li>
                                //                         ))}
                                //                     </ul>
                                //                 </li>
                                //             ))}
                                //         </ul>
                                //     </div>
                                // </div>
                            )}








                            <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                {apiCalls.story ? (
                                    <Skeleton height={128} />
                                ) : ''}
                                <div>

                                    {storyData?.introduction && (
                                        <div className="border-l-4 border-sky-500 pl-4 mb-8">
                                            <h3 className="text-lg font-semibold text-zinc-100 mb-3">Introduction</h3>
                                            <div className="bg-zinc-800/50 rounded p-4">
                                                <p className="text-zinc-300 leading-relaxed mb-6">{storyData.introduction.summary}</p>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {storyData.introduction.people.map((person, index) => (
                                                    <div key={index} className="bg-zinc-800/50 rounded-lg p-4">
                                                        <h4 className="text-zinc-100 font-medium">{person.name}</h4>
                                                        <p className="text-sky-400 text-xs">{person.role}</p>
                                                        <p className="text-zinc-400 text-sm">{person.goal}</p>
                                                        <div className="text-zinc-500 text-xs">
                                                            {person.age} years • {person.gender}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}


                                    {storyData?.scenes?.scenes && storyData.scenes.scenes.map((scene, index) => (
                                        <div key={index} className="border-l-4 border-sky-500 pl-4 mb-8">
                                            <div className="flex items-center gap-2 mb-3">
                                                <span className="bg-sky-500/20 text-sky-300 px-2 py-1 rounded-full text-xs">
                                                    Scene {index + 1}
                                                </span>
                                                <h3 className="text-lg font-semibold text-zinc-100">{scene.title}</h3>
                                            </div>
                                            <p className="text-zinc-300 mb-4">{scene.description}</p>
                                            <p className="text-zinc-300 mb-4">{scene.story}</p>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div className="bg-zinc-800/50 rounded p-4">
                                                    <h4 className="text-zinc-300 font-medium mb-2">Customer Value</h4>
                                                    <p className="text-zinc-400">{scene.customer_value}</p>
                                                </div>
                                                <div className="bg-zinc-800/50 rounded p-4">
                                                    <h4 className="text-zinc-300 font-medium mb-2">Business Value</h4>
                                                    <p className="text-zinc-400">{scene.business_value}</p>
                                                </div>
                                                <div className="bg-zinc-800/50 rounded p-4 col-span-2">
                                                    <h4 className="text-zinc-300 font-medium mb-2">Talk Track</h4>
                                                    <p className="text-zinc-400">{scene.talk_track}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                                    {storyData?.conclusion && (
                                        <div className="border-l-4 border-emerald-500 pl-4 mt-12">
                                            <h3 className="text-lg font-semibold text-zinc-100 mb-3">Conclusion</h3>
                                            <p className="text-zinc-300 leading-relaxed mb-6">{storyData.conclusion.summary}</p>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {storyData.conclusion.benefits.map((benefit, index) => (
                                                    <div key={index} className="bg-zinc-800/50 rounded-lg p-4">
                                                        <h4 className="text-zinc-100 font-medium mb-2">{benefit.name}</h4>
                                                        <p className="text-sky-400 text-xs">{benefit.role}</p>
                                                        <ul className="list-disc pl-4 space-y-1">
                                                            {benefit.benefits.map((b, idx) => (
                                                                <li key={idx}>{b}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                </div>

                            </div>

                        </div>














                        <div className="mt-4" ref={producedStoryRef}>
                            {/* <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.producer, (producedStory && producedStory.scenes))}`}>
                                    <div className="flex items-center">
                                        {apiCalls.producer ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Produced Story</div>
                                    </div>
                                </h2>
                            </div> */}

                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.producer, (producedStory && producedStory.scenes))}`}>
                                    <div className="flex items-center">
                                        {apiCalls.producer ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Produced Story</div>
                                    </div>
                                </h2>
                                {storyVisualizationData && (
                                    <button
                                        onClick={() => {
                                            const element = document.getElementById('producedStoryContent');
                                            // Add background class
                                            element.classList.add('bg-zinc-900');

                                            const opt = {
                                                filename: 'produced-story.pdf',
                                                image: { type: 'jpeg', quality: 0.98 },
                                                html2canvas: { scale: 2 },
                                                margin: .1,
                                                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
                                            };

                                            // Generate PDF and remove class after completion
                                            html2pdf().set(opt).from(element).save().then(() => {
                                                element.classList.remove('bg-zinc-900');
                                            });
                                        }}
                                        className="bg-zinc-700 hover:bg-zinc-600 text-white py-2 px-4 rounded text-sm flex items-center gap-2 mr-6"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Download PDF
                                    </button>
                                )}
                            </div>



                            <div id="producedStoryContent" className=' ' >
                                {storyVisualizationData && (
                                    <div className="p-6">
                                        <div className="bg-black/20 rounded p-8">
                                            <h2 className="text-2xl text-zinc-50 mb-2">{storyVisualizationData.title?.title}</h2>
                                            <div className="text-xl text-zinc-50 mb-4">{storyVisualizationData.title?.subtitle}</div>
                                        </div>

                                        {/* Opening Section */}
                                        <div className="mt-8">
                                            <h3 className="text-lg mb-2 text-sky-400">Opening</h3>
                                            <div className="bg-black/20 rounded p-8">
                                                <p className="leading-relaxed text-zinc-100">{storyVisualizationData.opening?.summary}</p>
                                                <p className="text-zinc-100 mt-4">{storyVisualizationData.opening?.challenge}</p>
                                            </div>

                                            {/* Participants */}
                                            <h3 className="text-lg mt-8 mb-2 text-sky-400">Stakeholders</h3>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                                                {storyVisualizationData.opening?.participants.map((person, index) => (
                                                    <div key={index} className="bg-black/20 rounded p-8">
                                                        <h4 className="text-zinc-50 text-lg">{person.name}</h4>
                                                        <p className="text-sky-400 text-base">{person.role}</p>
                                                        <p className="text-zinc-400 text-sm mt-2">{person.introduction}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {/* Closing Section */}
                                        <div className="mt-8">
                                            <h3 className="text-lg mb-2 text-sky-400">Conclusion</h3>
                                            <div className="bg-black/20 rounded p-8">
                                                <p className="leading-relaxed text-zinc-100">{storyVisualizationData.closing?.summary}</p>

                                                <div className="mt-6">
                                                    <h4 className="text-zinc-50 text-lg mb-4">Outcomes</h4>
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        {storyVisualizationData.closing?.outcomes.map((outcome, index) => (
                                                            <div key={index} className="bg-black/40 rounded p-4">
                                                                <h5 className="text-sky-400">{outcome.benefit}</h5>
                                                                <p className="text-zinc-300 mt-2">{outcome.impact}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="mt-6">
                                                    <h4 className="text-zinc-50 text-lg mb-4">Future Implications</h4>
                                                    <p className="text-zinc-300">{storyVisualizationData.closing?.future_implications}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>






                            {(apiCalls.producer || (producedStory && producedStory.scenes)) && (
                                <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto">
                                    {apiCalls.producer ? (
                                        <div className="grid grid-cols-1 gap-4">
                                            <Skeleton height={128} />
                                        </div>
                                    ) : producedStory && producedStory.scenes ? (
                                        <div>
                                            <div className="border-l-4 border-purple-500 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Introduction</h3>
                                                <p className="text-zinc-300 leading-relaxed">{producedStory.introduction?.summary}</p>
                                                <div className="mt-4 space-y-4">
                                                    <h4 className="text-zinc-300 font-medium">Key People</h4>
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        {producedStory.introduction?.people?.map((person, index) => (
                                                            <div key={index}>
                                                                <div className="bg-zinc-800/50 rounded p-4">
                                                                    <div className="flex items-center gap-2 mb-2">
                                                                        <span className="text-zinc-100 font-medium">{person.name}</span>
                                                                        <span className="bg-sky-500/20 text-sky-400 px-2 py-0.5 rounded-full text-xs">
                                                                            {person.role}
                                                                        </span>
                                                                    </div>
                                                                    <p className="text-zinc-400 text-sm mb-2">{person.goal}</p>
                                                                    <div className="text-zinc-500 text-xs">
                                                                        {person.age} years • {person.gender}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            {producedStory.scenes.scenes.map((scene, index) => (
                                                <div key={index} className='mt-12'>
                                                    <div className="border-l-4 border-sky-500 pl-4">
                                                        <div className="flex items-center gap-3 mb-3">
                                                            <span className="bg-sky-500/20 text-sky-300 px-2 py-1 rounded-full text-xs font-medium">
                                                                Scene {index + 1}
                                                            </span>
                                                            <h3 className="text-lg font-semibold text-zinc-100">{scene.title}</h3>
                                                        </div>
                                                        <p className="text-zinc-400 mb-4">{scene.description}</p>
                                                        <p className="text-zinc-300 mb-4">{scene.story}</p>
                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                            <div className="bg-zinc-800/50 rounded p-4">
                                                                <h4 className="text-zinc-300 font-medium mb-2">Customer Value</h4>
                                                                <p className="text-zinc-400">{scene.customer_value}</p>
                                                            </div>
                                                            <div className="bg-zinc-800/50 rounded p-4">
                                                                <h4 className="text-zinc-300 font-medium mb-2">Business Value</h4>
                                                                <p className="text-zinc-400">{scene.business_value}</p>
                                                            </div>
                                                            <div className="bg-zinc-800/50 rounded p-4 col-span-2">
                                                                <h4 className="text-zinc-300 font-medium mb-2">Talk Track</h4>
                                                                <p className="text-zinc-400">{scene.talk_track}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="border-l-4 border-emerald-500 pl-4 mt-12">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Conclusion</h3>
                                                <p className="text-zinc-300 mb-4">{producedStory.conclusion?.summary}</p>
                                                <div className="space-y-4">
                                                    <h4 className="text-zinc-300 font-medium">Benefits by Role</h4>
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        {producedStory.conclusion?.benefits?.map((person, index) => (
                                                            <div key={index}>
                                                                <div className="bg-zinc-800/50 rounded p-4">
                                                                    <div className="flex items-center gap-2 mb-2">
                                                                        <span className="text-zinc-100 font-medium">{person.name}</span>
                                                                        <span className="bg-sky-500/20 text-sky-400 px-2 py-0.5 rounded-full text-xs">
                                                                            {person.role}
                                                                        </span>
                                                                    </div>
                                                                    <ul className="list-disc pl-4 space-y-1">
                                                                        {person.benefits.map((benefit, idx) => (
                                                                            <li key={idx} className="text-zinc-400">{benefit}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}






{/* Add after the Produced Story section */}
<div className="flex justify-between items-center mt-8">
    <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.storyChapters, storyChaptersData)}`}>
        <div className="flex items-center">
            {apiCalls.storyChapters ? (
                <ImpulseSpinner frontColor="#38BDF8" className="inline" />
            ) : (
                <ThreeDotsSVG />
            )}
            <div className='ml-4'>Story Chapters</div>
        </div>
    </h2>
</div>

{storyChaptersData?.chapters && (
    <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto">
        {storyChaptersData.chapters.map((chapter, index) => (
            <div key={index} className="border-l-4 border-sky-500 pl-4 mb-8">
                <h3 className="text-lg font-semibold text-zinc-100 mb-3">
                    Chapter {chapter.chapterNumber}: {chapter.title}
                </h3>
                <div className="bg-zinc-800/50 rounded p-4 mb-4">
                    <p className="text-zinc-300 leading-relaxed">{chapter.summary}</p>
                </div>
                
                <div className="bg-zinc-800/50 rounded p-4 mb-4">
                    <h4 className="text-zinc-100 font-medium mb-2">Setting</h4>
                    <p className="text-zinc-300">{chapter.narrative.setting}</p>
                </div>

                <div className="bg-zinc-800/50 rounded p-4 mb-4">
                    <h4 className="text-zinc-100 font-medium mb-2">Main Points</h4>
                    <ul className="list-disc list-inside">
                        {chapter.narrative.mainPoints.map((point, idx) => (
                            <li key={idx} className="text-zinc-300 mb-1">{point}</li>
                        ))}
                    </ul>
                </div>

                <div className="bg-zinc-800/50 rounded p-4 mb-4">
                    <h4 className="text-zinc-100 font-medium mb-2">Key Participants</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {chapter.narrative.keyParticipants.map((participant, idx) => (
                            <div key={idx} className="bg-zinc-700/50 rounded p-3">
                                <h5 className="text-zinc-100">{participant.name}</h5>
                                <p className="text-sky-400 text-xs">{participant.role}</p>
                                <p className="text-zinc-300 text-sm">{participant.actions}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-zinc-800/50 rounded p-4 mb-4">
                    <h4 className="text-zinc-100 font-medium mb-2">Technical Details</h4>
                    <ul className="list-disc list-inside">
                        {chapter.narrative.technicalDetails.map((detail, idx) => (
                            <li key={idx} className="text-zinc-300 mb-1">{detail}</li>
                        ))}
                    </ul>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div className="bg-zinc-800/50 rounded p-4">
                        <h4 className="text-zinc-100 font-medium mb-2">Business Value</h4>
                        <p className="text-zinc-300">{chapter.narrative.businessValue}</p>
                    </div>
                    <div className="bg-zinc-800/50 rounded p-4">
                        <h4 className="text-zinc-100 font-medium mb-2">Customer Value</h4>
                        <p className="text-zinc-300">{chapter.narrative.customerValue}</p>
                    </div>
                </div>

                <div className="bg-zinc-800/50 rounded p-4">
                    <h4 className="text-zinc-100 font-medium mb-2">Talk Track</h4>
                    <p className="text-zinc-300">{chapter.talkTrack}</p>
                </div>
            </div>
        ))}
    </div>
)}


                        </div>




                        {/* <div className="mt-4" ref={talkTrackRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.talkTrack, talkTrackData?.scenes)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.talkTrack ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Talk Track</div>
                                    </div>
                                </h2>
                            </div>
                            {(apiCalls.talkTrack || talkTrackData?.scenes) && (
                                <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto transition-opacity duration-300 ease-in-out opacity-100">
                                    {apiCalls.talkTrack ? (
                                        <div className="grid grid-cols-1 gap-4">
                                            <Skeleton height={128} />
                                        </div>
                                    ) : talkTrackData && (
                                        <div className="space-y-8 px-10 py-6">
                                            {talkTrackData.introduction?.talk_track && (
                                                <div className="border-l-4 border-sky-500 pl-4">
                                                    <h3 className="text-lg font-semibold text-zinc-100 mb-3">Introduction</h3>
                                                    <p className="text-zinc-300 leading-relaxed">{talkTrackData.introduction.talk_track}</p>
                                                </div>
                                            )}

                                            {talkTrackData.scenes?.length > 0 && (
                                                <div className="space-y-6 mt-12">
                                                    {talkTrackData.scenes.map((scene) => (
                                                        <div key={scene.scene_number} className="border-l-4 border-sky-500 pl-4">
                                                            <div className="flex items-center gap-3 mb-2">
                                                                <span className="bg-sky-500/20 text-sky-400 px-2 py-1 rounded-full text-xs font-medium">
                                                                    Scene {scene.scene_number}
                                                                </span>
                                                                <h4 className="text-zinc-100 font-medium">{scene.title}</h4>
                                                            </div>
                                                            <p className="text-zinc-300 leading-relaxed">{scene.talk_track}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {talkTrackData.conclusion?.talk_track && (
                                                <div className="border-l-4 border-sky-500 pl-4 mt-4">
                                                    <h3 className="text-lg font-semibold text-zinc-100 mb-3">Conclusion</h3>
                                                    <p className="text-zinc-300 leading-relaxed">{talkTrackData.conclusion.talk_track}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div> */}

                        <div className="mt-4" ref={salesAnalysisRef}>
                            <div className="flex justify-between items-center">
                                <h2 className={`mb-2 ml-4 text-base flex items-center ${getHeadingClass(apiCalls.salesAnalysis, salesAnalysisData?.customerChallenge)}`}>
                                    <div className="flex items-center">
                                        {apiCalls.salesAnalysis ? (
                                            <ImpulseSpinner frontColor="#38BDF8" className="inline" />
                                        ) : (
                                            <ThreeDotsSVG />
                                        )}
                                        <div className='ml-4'>Sales Analysis</div>
                                    </div>
                                </h2>
                                {/* {salesAnalysisData?.customerChallenge && (
                                <button
                                    onClick={() => generateSectionPDF(salesAnalysisRef, 'Sales_Analysis')}
                                    className="bg-zinc-800 hover:bg-zinc-600 text-white py-1 px-3 rounded text-sm"
                                >
                                    Download PDF
                                </button>
                            )} */}
                            </div>
                            {(apiCalls.salesAnalysis || salesAnalysisData?.customerChallenge) && (
                                <div className="rounded text-sm text-zinc-300 h-content overflow-y-auto transition-opacity duration-300 ease-in-out opacity-100">
                                    {apiCalls.salesAnalysis ? (
                                        <div className="grid grid-cols-1 gap-4">
                                            <Skeleton height={128} />
                                        </div>
                                    ) : salesAnalysisData && (
                                        <div className="space-y-8 px-10 py-6">
                                            {/* Customer Challenge */}
                                            <div className="border-l-4 border-emerald-500/20 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Customer Challenge</h3>
                                                <div className="space-y-4">
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Impact</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.customerChallenge?.impact?.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Urgency</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.customerChallenge.urgency.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Solution Alignment */}
                                            <div className="border-l-4 border-sky-500/20 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Solution Alignment</h3>
                                                <div className="space-y-4">
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Key Features</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.solutionAlignment.keyFeatures.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Benefits</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.solutionAlignment.benefits.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Value Proposition */}
                                            <div className="border-l-4 border-purple-500/20 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Value Proposition</h3>
                                                <div className="space-y-4">
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Key Messages</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.valueProposition.keyMessages.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Unique Value</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.valueProposition.uniqueValue.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Objection Handling */}
                                            <div className="border-l-4 border-amber-500/20 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Objection Handling</h3>
                                                <div className="space-y-4">
                                                    {salesAnalysisData.objectionHandling.anticipatedObjections.map((objection, i) => (
                                                        <div key={i} className="bg-zinc-800/50 rounded p-4">
                                                            <p className="text-zinc-200 font-medium mb-2">Objection: {objection}</p>
                                                            <p className="text-zinc-400">Response: {salesAnalysisData.objectionHandling.responses[i]}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Presentation Strategy */}
                                            <div className="border-l-4 border-rose-500/20 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Presentation Strategy</h3>
                                                <div className="space-y-4">
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Best Practices</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.presentationStrategy.bestPractices.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Personalization</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.presentationStrategy.personalization.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Next Steps */}
                                            <div className="border-l-4 border-indigo-500/20 pl-4">
                                                <h3 className="text-lg font-semibold text-zinc-100 mb-3">Next Steps</h3>
                                                <div className="space-y-4">
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Action Items</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.nextSteps.actionItems.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-zinc-300 font-medium mb-2">Follow-up Strategy</h4>
                                                        <ul className="list-disc pl-4 space-y-2">
                                                            {salesAnalysisData.nextSteps.followUpStrategy.map((item, i) => (
                                                                <li key={i} className="text-zinc-400">{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </SkeletonTheme>
    );
}